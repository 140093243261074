import React from 'react';
import styled from 'styled-components';
import { LaunchIcon } from '../icons/launch';
import { PhoneIcon } from '../icons/phone';
import { QuickReplyIcon } from '../icons/quick_reply';

function MessageButtons(props) {
  const { buttons, isQR, highlightTags } = props;

  const getIcon = (icon, text = '') => {
    let Icon = icon ? IconMap[icon] : null;

    if (highlightTags) Icon = QuickReplyIcon;

    if (!!icon && !Icon) {
      console.warn(`Icon "${icon}" for button "${text}" not resolved`);
    }

    return Icon;
  };

  return (
    <MessageButtonContainer isQR={isQR}>
      {buttons.map((item, idx) => {
        const getButton = (icon, text, isQR) => {
          const Icon = getIcon(icon, text);

          return isQR ? (
            <MessageButtonQR key={text} fullWidth={idx === 2}>
              <Text>{text}</Text>
            </MessageButtonQR>
          ) : (
            <MessageButton key={text} withIcon={!!Icon} lastIndex={idx === buttons.length - 1}>
              {!!Icon && <Icon width={18} height={18} />}

              <Text className="mt-[3px]">{text}</Text>
            </MessageButton>
          );
        };

        if (Array.isArray(item)) {
          return (
            <>
              {item.map(({ text, icon }) => {
                return getButton(icon, text, isQR);
              })}
            </>
          );
        } else {
          return getButton(item.icon, item.text, isQR);
        }
      })}
    </MessageButtonContainer>
  );
}

const MessageButtonContainer = styled.div`
  row-gap: 4px;
  column-gap: 4px;
  width: 100%;
  margin-top: ${(props) => props.isQR && '4px'};
  display: ${(props) => props.isQR && 'flex'};
  flex-direction: ${(props) => props.isQR && 'row'};
  flex-wrap: ${(props) => props.isQR && 'wrap'};
`;

const MessageButton = styled.div`
  border-top: 1px solid #d8dcde;
  text-align: center;
  display: grid;
  place-items: center;
  align-items: center;
  justify-content: center;
  padding: 15px 23.5px;
  background: #ffffff;
  border-radius: 4px;
  color: #1f73b7;
  grid-template-columns: ${(props) => props.withIcon && 'repeat(2, max-content)'};
  gap: ${(props) => props.withIcon && '7px'};
  margin: 0 40px;
  border-bottom-left-radius: ${(props) => props.lastIndex && '20px'};
  border-bottom-right-radius: ${(props) => props.lastIndex && '20px'};
`;

const MessageButtonQR = styled.span`
  flex: 1;
  flex-grow: 1;
  display: inline-block;
  padding: 7px 23.5px;

  background: #ffffff;
  border-radius: 4px;

  color: #1890ff;
  flex-basis: ${(props) => (props.fullWidth ? '100%' : '')};
`;

const Text = styled.span`
  display: inline-block;
  width: 100%;
  max-width: 180px;
  font-weight: 400;
  overflow: hidden;

  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 22px;
`;

const IconMap = {
  URL: LaunchIcon,
  PHONE: PhoneIcon,
};

export default MessageButtons;
