import styled from 'styled-components';
import { formatToWA } from '../../../Utilities/format';
import { replateTextWithVarsValue } from '../template/templateAdapter';
import AttachMedia from './attach-media';
import MessageButtons from './buttons';
import { MessageImg } from './image';

const MessagePreview = (props) => {
  const { template, onChange, file } = props;

  const fileUploadTooltip = () => {
    return (
      <>
        Select the media for the pre-approved template message.
        <br />
        Ideal image size: 1125 x 600 px
        <br />
        Allowed file sizes:
        <br />
        Image 5.00 MB
        <br />
        PDF 50.00 MB
      </>
    );
  };

  const fileUploadBodyContent = () => {
    return (
      <span>
        Choose a file
        <br />
        {!template?.content?.isPdfMedia ? <>Acceptable formats are JPG, PNG, and GIF</> : <>Acceptable format is PDF</>}
      </span>
    );
  };

  if (!template) return null;

  if (template?.type === 'text') {
    return (
      <>
        {template?.content?.media && (
          <AttachMedia
            template={template}
            onChange={onChange}
            tooltip={fileUploadTooltip()}
            label="Attach media"
            bodyContent={fileUploadBodyContent()}
          />
        )}
        <StyleSpan>Message Preview</StyleSpan>
        <StandartMessageTemplate template={template} file={file} />
      </>
    );
  }

  if (template.type === 'quickReply') {
    return (
      <>
        {template?.content?.media && (
          <AttachMedia
            template={template}
            onChange={onChange}
            tooltip={fileUploadTooltip()}
            label="Attach media"
            bodyContent={fileUploadBodyContent()}
          />
        )}
        <StyleSpan>Message Preview</StyleSpan>
        <QuickReplyMessageTemplate template={template} file={file} />
      </>
    );
  }

  return (
    <>
      {template?.content?.media && (
        <AttachMedia
          template={template}
          onChange={onChange}
          tooltip={fileUploadTooltip()}
          label="Attach media"
          bodyContent={fileUploadBodyContent()}
        />
      )}
      <StyleSpan>Message Preview</StyleSpan>
      <CTAMessageTemplate template={template} file={file} />
    </>
  );
};

const StandartMessageTemplate = (props) => {
  const { template, file } = props;

  const textAsHTML = formatToWA(replateTextWithVarsValue(template?.content?.text, template?.vars));
  return (
    <Container>
      {!!template.content.media ? (
        <Message>
          <MessageImg template={template} file={file} />

          <MessageText dangerouslySetInnerHTML={{ __html: textAsHTML }} />
        </Message>
      ) : (
        <Message dangerouslySetInnerHTML={{ __html: textAsHTML }} />
      )}
    </Container>
  );
};

const QuickReplyMessageTemplate = (props) => {
  const { template, file } = props;
  const textAsHTML = formatToWA(replateTextWithVarsValue(template?.content?.text, template?.vars));
  return (
    <Container>
      {!!template.content.media ? (
        <Message>
          <MessageImg template={template} file={file} />

          <MessageText dangerouslySetInnerHTML={{ __html: textAsHTML }} />
        </Message>
      ) : (
        <Message dangerouslySetInnerHTML={{ __html: textAsHTML }} />
      )}

      <MessageButtons buttons={template.content.buttons} isQR={true} />
    </Container>
  );
};

const CTAMessageTemplate = (props) => {
  const { template, file } = props;
  const textAsHTML = formatToWA(replateTextWithVarsValue(template?.content?.text, template?.vars));
  return (
    <Container>
      <Message>
        {template.content.media && <MessageImg template={template} file={file} />}

        <MessageText dangerouslySetInnerHTML={{ __html: textAsHTML }} />
      </Message>

      <MessageButtons buttons={template.content.buttons} isQR={false} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 320px;
  // width: 100%;
  margin: 0px auto;

  padding: 10px;

  background: rgba(235, 226, 222, 0.7);
  border: 1px solid #d8dcde;
  border-radius: 4px;
`;

const Message = styled.div`
  padding: 5px 10px;

  // width: 100%;

  background: #ffffff;
  border-radius: 4px;

  white-space: pre-wrap;
  word-break: break-word;

  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2f3941;
`;

const MessageText = styled.p`
  margin: 0;
  margin-top: 10px;
`;

const StyleSpan = styled.span`
  font-size: 12px;
  font-weight: 700;
  // margin-bottom: -15px;
  color: #03363d;
`;

export { MessagePreview };
