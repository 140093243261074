import React, { useRef, useState, useEffect } from 'react';
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from '@zendeskgarden/react-tables';
import { Button } from '@zendeskgarden/react-buttons';
import { useNavigate } from 'react-router-dom';
import TemplatePreview from '../../components/TemplatePreview';
import MenuModal from '../template-wizards/MenuModal';
import AlertNotifcation from '../../components/notification/alert-notification';
import { useBackend } from '../../api/BackendApiService';
import { Spinner } from '@zendeskgarden/react-loaders';
import Channel from '../../components/channels';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import { capitalizeFirstLetter } from '../../Utilities/capitalize';

const menuImg = '/asset/menu.svg';
const refreshIcon = '/asset/refresh.svg';

function TemplateList({ tab, onSendClick }) {
  const { getAppTemplates, deleteTemplate, addTemplate, syncTemplates, channels, authData, clientData } = useBackend();
  const [showPreview, setShowPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [notification, setNotification] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const navigate = useNavigate();

  const buttonRef = useRef(null);

  useEffect(() => {
    setSelectedChannel(channels.find((item) => item.id === authData?.channel?.id));
    if (tab === 'viewTemplatesTab') {
      fetchAllTemplates(authData?.channel?.id);
    }
  }, [tab]);

  const fetchAllTemplates = async (channelId) => {
    setLoading(true);
    const res = await getAppTemplates(channelId);
    setLoading(false);
    setTemplates(res);
  };

  const onMenuButtonClick = (template, menuItem) => {
    if (menuItem?.toLowerCase() === 'preview') {
      setShowPreview(true);
    }
    if (menuItem?.toLowerCase() === 'delete') {
      setNotification(true);
    }
    if (menuItem?.toLowerCase() === 'duplicate') {
      navigate('/create-template', { state: { clientData, template: template } });
    }
    if (menuItem?.toLowerCase() === 'resubmit') {
      reSubmitTemplate(template);
    }

    setSelectedTemplate(template);
  };

  const reSubmitTemplate = async (template) => {
    template?.components?.forEach((component) => {
      if (component.type === 'BODY' && component.example && component.example.body_text) {
        const bodyTextValue = component.example.body_text;
        delete component.example;

        component.exampleArray = bodyTextValue;
      }
    });
    const payload = {
      channelId: selectedChannel?.id?.toString(),
      name: template?.name,
      components: template?.components,
      category: template?.category,
      language: template?.language,
    };
    try {
      const response = await addTemplate(payload);
      navigate('/template-viewer', { state: { clientData } });
    } catch (error) {
      console.log('Error : ', error);
    }
  };

  const onAlertButtonClick = async (text) => {
    if (text.toLowerCase() === 'delete') {
      setLoading(true);
      const res = await deleteTemplate({ name: selectedTemplate?.name, channelId: selectedChannel?.id?.toString() });
      setSelectedTemplate(null);
      setShowPreview(false);
      setLoading(false);
      fetchAllTemplates(selectedChannel?.id);
    }
    setNotification(false);
  };

  const menuItem = [
    {
      name: 'Preview',
      onClick: onMenuButtonClick,
    },
    {
      name: 'Duplicate',
      onClick: onMenuButtonClick,
    },
    {
      name: 'Resubmit',
      onClick: onMenuButtonClick,
    },
    {
      name: 'Delete',
      onClick: onMenuButtonClick,
      textColor: '#D93F4C',
    },
  ];

  const AlertButtons = [
    {
      text: 'Go Back',
      onClick: onAlertButtonClick,
      color: '#CC3340',
    },
    {
      text: loading ? 'Deleting...' : 'Delete',
      onClick: onAlertButtonClick,
      disabled: loading ? true : false,
    },
  ];

  const onCloseClick = () => {
    setShowPreview(false);
    setSelectedTemplate(null);
  };

  const onChannelChange = (item) => {
    setShowPreview(false);
    setSelectedChannel(item);
    fetchAllTemplates(item?.id);
  };

  const onRefreshClick = () => {
    setLoading(true);
    syncTemplates(selectedChannel?.id);
    setTimeout(() => {
      fetchAllTemplates(selectedChannel?.id);
    }, 5000);
  };

  return (
    <div className="flex flex-col">
      <div className="grid gap-[20px] mx-5 mb-12">
        <div
          className={`flex ${
            channels.filter((ele) => ele.isSetup).length > 1 ? 'justify-between gap-4' : 'justify-end'
          }`}
        >
          {channels.filter((ele) => ele.isSetup).length > 1 && (
            <div className="w-full">
              <Channel
                onChannelChange={onChannelChange}
                selectedChannel={selectedChannel}
                lable="Select Channel"
                tooltip="Templates are connected to WhatsApp channels"
              />
            </div>
          )}
          <Tooltip content="Refresh" size="small">
            <div
              className="cursor-pointer flex mb-[2px] hover:opacity-75 items-end h-10 self-end"
              onClick={() => onRefreshClick()}
            >
              <img src={refreshIcon} alt="Refresh" />
            </div>
          </Tooltip>
        </div>
        {showPreview && selectedTemplate && (
          <div className="grid gap-5">
            <TemplatePreview
              components={selectedTemplate?.components}
              variables={selectedTemplate?.variables}
              showHeader={true}
              highlightTags={true}
              selectedTemplate={selectedTemplate}
              onCloseClick={() => onCloseClick()}
            />
          </div>
        )}
        <div className="overflow-x-auto rounded-[6px] mb-5">
          <Table size="medium">
            <Head className="bg-[#F8FAFC]">
              <HeaderRow>
                <HeaderCell className="w-[45%]">
                  <span className="text-sm font-medium text-[#2F3941]">TEMPLATE NAME</span>
                </HeaderCell>
                <HeaderCell className="w-[20%]">
                  <span className="text-sm font-medium text-[#2F3941]">STATUS</span>
                </HeaderCell>
                <HeaderCell className="w-[15%]">
                  <span className="text-sm font-medium text-[#2F3941]">ACTION</span>
                </HeaderCell>
                <HeaderCell className="w-[10%]">
                  <span className="text-sm font-medium text-[#2F3941]"></span>
                </HeaderCell>
              </HeaderRow>
            </Head>
            <Body>
              {loading && (
                <Row>
                  <div className="flex w-[450px] justify-center p-2">
                    <Spinner size="30" color="#1f73b7" />
                  </div>
                </Row>
              )}
              {!!templates?.items?.length &&
                !loading &&
                templates?.items?.map((temp, idx) => (
                  <Row>
                    <Cell isTruncated style={{ color: '#2F3941' }}>
                      <Tooltip content={temp?.name}>
                        <span>{temp.name}</span>
                      </Tooltip>
                    </Cell>
                    <Cell style={{ color: '#2F3941' }}>{capitalizeFirstLetter(temp.status)}</Cell>
                    <Cell
                      className={`${
                        temp.status === 'APPROVED'
                          ? 'text-[#2563EB] cursor-pointer'
                          : 'text-[#D1D5DB] cursor-not-allowed'
                      }`}
                      onClick={() => temp.status === 'APPROVED' && onSendClick(temp)}
                    >
                      Send
                    </Cell>
                    <Cell ref={buttonRef}>
                      <MenuModal
                        Trigger={<img src={menuImg} alt="Menu" className="cursor-pointer" />}
                        menuItem={menuItem}
                        rowData={temp}
                      ></MenuModal>
                    </Cell>
                  </Row>
                ))}
              {!templates?.items?.length && !loading && (
                <Row>
                  <div className="h-10 flex items-center ml-2">No templates found</div>
                </Row>
              )}
            </Body>
          </Table>
        </div>
        {notification && (
          <AlertNotifcation
            title="Caution"
            type="error"
            body="This will delete the template for all agents. Are you sure you want to proceed?"
            buttons={AlertButtons}
            notification={notification}
            setNotification={setNotification}
            showCheckbox={true}
            checkboxText="I'm sure"
          />
        )}
      </div>
      <Button
        className="w-fit self-end"
        style={{ marginRight: '20px', marginBottom: '20px' }}
        isPrimary
        size="small"
        onClick={() => navigate('/create-template', { state: { clientData } })}
      >
        Submit Another
      </Button>
    </div>
  );
}

export default TemplateList;
