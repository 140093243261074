import styled from 'styled-components';

const imgPlaceholder = '/img-placeholder.png';
const docPlaceholder = '/doc-placeholder.png';
const greyBgImage =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8/B8AAosBxERSgsYAAAAASUVORK5CYII=';

const MessageImg = (props) => {
  const { template } = props ?? {};
  const src = template?.content?.isPdfMedia ? docPlaceholder : imgPlaceholder;

  const onError = (e) => {
    e.target.src = greyBgImage;
  };

  return <Img alt="" showTop={template?.content?.isPdf} src={src} onError={onError} />;
};

const Img = styled.img`
  position: relative;

  height: 114px;
  width: 100%;

  object-fit: cover;
  object-position: ${(props) => props.showTop && 'top'};
  border-radius: 3px;
`;

export { MessageImg };
