import React, { useEffect } from 'react';
import { TooltipModal } from '@zendeskgarden/react-modals';

function TooltipModalComponent(props) {
  const {
    referenceElement,
    setReferenceElement,
    title,
    body,
    modalStyle,
    titleStyle,
    bodyStyle,
    placement,
    hasArrow,
    close,
    onClose,
    popperModifiers,
  } = props;

  return (
    <TooltipModal
      style={modalStyle}
      referenceElement={referenceElement}
      onClose={() => {
        if (onClose) {
          onClose();
          return;
        }
        setReferenceElement(null);
        return;
      }}
      placement={placement || 'start'}
      hasArrow={hasArrow}
      popperModifiers={popperModifiers}
    >
      {title && (
        <TooltipModal.Title tag="h2" style={titleStyle}>
          {title}
        </TooltipModal.Title>
      )}
      {body && <TooltipModal.Body style={bodyStyle}>{body}</TooltipModal.Body>}
      {close && <TooltipModal.Close aria-label="Close" />}
    </TooltipModal>
  );
}

export default TooltipModalComponent;
