import { EditorState, Modifier, SelectionState } from 'draft-js';

export const onBackspaceClick = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selectionState.getStartKey());
  const text = block.getText();
  const cursorPosition = selectionState.getStartOffset();
  let variableNumber = 0;
  // Regular expression to find placeholders
  const placeholderRegex = /\{\{(\d+)\}\}/g;
  let match,
    nearestPlaceholderRange = null;
  // Find the nearest placeholder to the cursor
  while ((match = placeholderRegex.exec(text)) !== null) {
    const matchStart = match.index;
    const matchEnd = match.index + match[0].length;
    // Check if cursor is inside or just before the placeholder
    if (cursorPosition >= matchStart && cursorPosition <= matchEnd + 2) {
      variableNumber = match[1];
      nearestPlaceholderRange = { start: matchStart, end: matchEnd + 2 };
      break; // Found the nearest placeholder, no need to continue
    }
  }

  // If a nearest placeholder is found and conditions are met, remove it
  // If a placeholder is found near the cursor, remove it
  if (nearestPlaceholderRange !== null) {
    const blockKey = block.getKey();
    // Create a new selection state that covers the range of the placeholder
    const placeholderSelection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: nearestPlaceholderRange.start,
      focusOffset: nearestPlaceholderRange.end,
    });

    // Remove the placeholder
    const newContentState = Modifier.removeRange(contentState, placeholderSelection, 'backward');
    // Push the new content state to the editor state, causing a re-render
    const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
    // Set the new editor state with the placeholder removed
    return { variableNumber: +variableNumber, newEditorState };
  }

  return { variableNumber: 0, editorState }; // Return the original editor state if no placeholder is found/removed
};

export const getCursorPositionAndText = (editorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selectionState.getStartKey());
  const text = block.getText();
  const cursorPosition = selectionState.getStartOffset();
  return { cursorPosition, text };
};

export const getVariablesFromText = (text) => {
  const numbersInBrackets = text?.match(/{{(\d+)}}/g);

  const numbers = numbersInBrackets?.map((match) => {
    const numberInBracket = match.match(/\d+/);
    return parseInt(numberInBracket[0]);
  });
  return numbers || [];
};

export const updateVariableSequence = (segments) => {
  let placeholderNumber = 1;
  return segments.map((segment) => segment.replace(/\{\{\d+\}\}/g, () => `{{${placeholderNumber++}}}`));
};

export const removeVariable = (placeholderToRemove, message) => {
  let segments = message.split(/(\{\{\d+\}\})/);
  segments = segments.filter((segment) => segment !== `{{${placeholderToRemove}}}`);
  const updatedSegments = updateVariableSequence(segments);
  return updatedSegments.join('');
};

export const addVariable = (message) => {
  let segments = message.split(/(\{\{\d+\}\})/);
  const updatedSegments = updateVariableSequence(segments);
  return updatedSegments.join('');
};
