import React from 'react';
import { createSvgIcon } from '.';

const QuickReplyIcon = createSvgIcon(
  '0 0 16 15',
  <path
    d="M7.82927 1.46647V4.77222C7.82927 4.88268 7.91958 4.97206 8.03002 4.97369C11.8936 5.03092 14.8007 6.77402 14.9902 13.3071C14.996 13.5075 14.7332 13.5868 14.6196 13.4215C12.7388 10.6856 10.8049 9.71223 8.02987 9.66824C7.91943 9.66649 7.82927 9.75621 7.82927 9.86667V13.5335C7.82927 13.7093 7.61873 13.7996 7.49138 13.6784L1.15221 7.64487C1.06939 7.56604 1.06939 7.43396 1.15221 7.35513L7.49138 1.32159C7.61873 1.20039 7.82927 1.29066 7.82927 1.46647Z"
    stroke="#1f73b7"
    stroke-linejoin="round"
    strokeWidth="0.75px"
  />
);

export { QuickReplyIcon };
