import { KEY_CODES } from '@zendeskgarden/container-utilities';
import { Field, File, FileList, FileUpload, Input, Label, Message } from '@zendeskgarden/react-forms';
import { Tooltip } from '@zendeskgarden/react-tooltips';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { keyframes } from 'styled-components';
import { InfoTooltip } from '../../../components/tooltip';
import { acceptExcelAndCSV, acceptImages, acceptPDF, mediaMaxSize, PDF_FILE_TYPE } from '../constants';
import { Progress } from '@zendeskgarden/react-loaders';

function AttachMedia(props) {
  const { template, onChange, tooltip, label, bodyContent, style, progressValue, showLoading, contactUpload } = props;
  const [file, setFile] = useState(null);

  useEffect(() => {
    setFile(null);
    onChange('file', null);
  }, [template]);

  const isSizeValid = (file, isPdf, isExcel = false) => {
    if (!file) return false;

    return isPdf
      ? mediaMaxSize.pdf > file.size
      : isExcel
      ? mediaMaxSize.excel > file.size
      : mediaMaxSize.image > file.size;
  };

  const handleFileKeyDown = (e) => {
    if (e.keyCode === KEY_CODES.DELETE || e.keyCode === KEY_CODES.BACKSPACE) {
      e.preventDefault();
      removeFile();
    }
  };

  const removeFile = () => {
    setFile(null);
    onChange('file', null);
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const newFile = acceptedFiles[0];

      setFile(newFile);
      if (
        (!contactUpload && isSizeValid(newFile, template?.content?.isPdfMedia)) ||
        (contactUpload && isSizeValid(newFile, false, true))
      ) {
        onChange('file', newFile);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: !contactUpload ? (template?.content?.isPdfMedia ? acceptPDF : acceptImages) : acceptExcelAndCSV,
    onDrop,
    noDragEventsBubbling: true,
  });

  return (
    <Field>
      <UploadFileLabelContainer>
        <UploadLabel>{label}</UploadLabel>
        {tooltip && <InfoTooltip tooltip={tooltip} />}
      </UploadFileLabelContainer>

      {!file && (
        <StyledFileUpload {...getRootProps()} disabled={false} isDragging={isDragActive} style={style}>
          {bodyContent}

          <Input {...getInputProps()} disabled={false} />
        </StyledFileUpload>
      )}

      {file && (
        <>
          <FileList>
            <FileListItem>
              <FileListFile
                type={file.type === PDF_FILE_TYPE ? 'pdf' : 'image'}
                title={file.name}
                tabIndex={0}
                aria-label="Image file"
                onKeyDown={handleFileKeyDown}
                isCompact
                validation={isSizeValid(file, template?.content?.isPdf, contactUpload) ? undefined : 'error'}
              >
                <FileName>{file.name}</FileName>

                <Tooltip content="Remove file">
                  <DeleteFile aria-label="delete" onClick={() => removeFile()} disabled={false} tabIndex={-1} />
                </Tooltip>
              </FileListFile>
            </FileListItem>
          </FileList>

          {!isSizeValid(file, template?.content?.isPdfMedia, contactUpload) && (
            <ErrorMessage>File too big</ErrorMessage>
          )}
          {showLoading && (
            <div className="grid gap-5 border-[1px] border-dashed border-[#68737D] rounded justify-center mt-5">
              <span className="text-[14px] font-bold text-[#1F73B7] mt-12 text-center">Uploading Contacts...</span>
              <Progress
                size="medium"
                value={+progressValue * 10}
                aria-label="Harvesting crops"
                style={{ marginBottom: '48px', width: '280px' }}
              />
            </div>
          )}
        </>
      )}
    </Field>
  );
}

//upload
const UploadFileLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: max-content 16px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const StyledFileUpload = styled(FileUpload)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-width: 2px;
  color: #1890ff;
  border-color: #91d5ff;
`;

const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const DeleteFile = styled(File.Delete)`
  margin-left: auto;
  cursor: ${(props) => props.disabled && 'not-allowed'};
  pointer-events: ${(props) => props.disabled && 'none'};
`;

const fadeOut = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const FileListItem = styled(FileList.Item)`
  opacity: 1;
  animation: ${fadeOut} 0.25s linear forwards;
`;

const FileListFile = styled(File)`
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
`;

const ErrorMessage = styled(Message)`
  margin-top: 8px;
  padding-left: 4px;
`;

const UploadLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

export default AttachMedia;
