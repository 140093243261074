import React, { useEffect, useState } from 'react';

import { Dropdown, Field, Menu, Item, Label, Autocomplete } from '@zendeskgarden/react-dropdowns';
import { useBackend } from '../../../api/BackendApiService';
import styled from 'styled-components';
import { InfoTooltip } from '../../../components/tooltip';

function Language({ selectedLanguage, onChangeLanguage, showAllLanguage, fontSize, label, color }) {
  const { languages, allLanguages } = useBackend();
  const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    setLanguageList(showAllLanguage ? allLanguages : languages);
  }, [languages]);

  const onSearchLanguages = (value) => {
    const languageList = showAllLanguage ? allLanguages : languages;
    if (!!value.length) {
      let matchedOptions = languageList?.filter((item) => {
        return item.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1;
      });
      setLanguageList(matchedOptions);
      return;
    }
    setLanguageList(languageList);
  };

  return (
    <Dropdown
      selectedItem={selectedLanguage}
      onSelect={(item) => onChangeLanguage(item)}
      downshiftProps={{ itemToString: (item) => item && item }}
      onInputValueChange={(value) => onSearchLanguages(value)}
    >
      <Field>
        <SelectLabelContainer>
          <SelectLabel fontSize={fontSize} color={color}>
            {label}
          </SelectLabel>
          <InfoTooltip tooltip="Choose the language submitted for the pre-approved template message you want to use" />
        </SelectLabelContainer>

        <Autocomplete disabled={false} isCompact>
          {selectedLanguage?.toUpperCase()}
        </Autocomplete>
      </Field>
      <Menu>
        {!!languageList?.length ? (
          languageList.map((option) => (
            <Item key={option} value={option}>
              {option.toUpperCase()}
            </Item>
          ))
        ) : (
          <Item disabled>Loading...</Item>
        )}
      </Menu>
    </Dropdown>
  );
}

const SelectLabel = styled(Label)`
  color: ${(props) => props?.color && props?.color};
  font-size: ${(props) => (props?.fontSize ? props?.fontSize : '12px')};
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

export default Language;
