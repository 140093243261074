import { Autocomplete, Dropdown, Field, Item, Label, Menu } from '@zendeskgarden/react-dropdowns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../../api/BackendApiService';
import { InfoTooltip } from '../../../components/tooltip';
import { getMessageType } from './templateAdapter';
import { useNavigate } from 'react-router-dom';

function Template({ onChangeTemplate, selectedTemplate, clientData }) {
  const { templates } = useBackend();
  const [templateList, setTemplateList] = useState([]);
  const [type, setType] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    setTemplateList(templates);
    setType(null);
  }, [templates]);

  const onChangeTemplateLanguage = (item) => {
    setSearchTerm('');
    const selectedTemplateData = getMessageType(item);
    if (selectedTemplateData?.type === 'quickReply') setType('Quick Reply');
    else if (selectedTemplateData?.type === 'CTA') setType('CTA Template');
    else setType('Standard');
    onChangeTemplate(item);
  };

  const onSearchTemplates = (value) => {
    setSearchTerm(value);
    if (value?.length > 0) {
      const matchedOptions = templates?.filter((option) => {
        return option.name.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1;
      });
      setTemplateList(matchedOptions);
    } else {
      setTemplateList(templates);
    }
  };

  return (
    <Container>
      <Dropdown
        inputValue={searchTerm}
        selectedItem={selectedTemplate}
        onSelect={(item) => onChangeTemplateLanguage(item)}
        downshiftProps={{ itemToString: (item) => item && item.name }}
        onInputValueChange={(value) => onSearchTemplates(value)}
      >
        <Field>
          <SelectLabelContainer>
            <SelectLabel>
              <div className="flex justify-between">
                <div className="flex gap-1">
                  <span className="text-[#03363d] text-sm font-bold">Select Template</span>
                  <InfoTooltip tooltip="Select your pre-approved WhatsApp template message" />
                </div>
                {clientData?.location === 'top_bar' && (
                  <span
                    className="underline text-[#1F73B7] cursor-pointer text-sm font-normal"
                    onClick={() => navigate('/create-template', { state: { clientData: clientData }, replace: true })}
                  >
                    Create Template
                  </span>
                )}
              </div>
            </SelectLabel>
          </SelectLabelContainer>
          <Autocomplete disabled={false} isCompact>
            {selectedTemplate?.name}
          </Autocomplete>
        </Field>

        <Menu>
          {templates && templateList ? (
            templateList.length === 0 ? (
              <Item disabled>No Such Template</Item>
            ) : (
              templateList.map((option) => (
                <Item key={option.id} value={option}>
                  {option.name}
                </Item>
              ))
            )
          ) : (
            <Item disabled>Loading...</Item>
          )}
        </Menu>
      </Dropdown>

      {selectedTemplate && type && (
        <TagContainer>
          <Tag>{type}</Tag>
        </TagContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 11px;
`;

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

//To reset grid stretch
const TagContainer = styled.div``;

const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: unset;

  padding: 1px 8px;

  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 2px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #1890ff;
`;

export default Template;
