import React, { useRef, useState } from 'react';
import { Dropdown, Field, Hint, Item, Label, Menu, Autocomplete } from '@zendeskgarden/react-dropdowns';
import Tags from './tags';
import TooltipModalComponent from '../../../components/tooltip/TooltipModal';
import AttachMedia from '../message-preview/attach-media';
import TemplateNotification from '../../template-wizards/notification/notification';
// import { storage } from '../../../Utilities/firebase';
// import { getDownloadURL, ref, uploadBytesResumable, listAll } from 'firebase/storage';
import { useBackend } from '../../../api/BackendApiService';
import { TIER_LIMIT } from '../../settings/constants';

const errorTooltip = '/asset/errorTooltip.svg';
const excelLogo = '/asset/excel.svg';
const errorIcon = '/asset/error-icon.svg';
const successIcon = '/asset/success-icon.svg';

const options = [
  { id: 'placeholder', value: 'Choose Options' },
  { id: 'tags', value: 'Zendesk customer tags' },
  { id: 'file', value: 'Contact file upload (Excel)' },
];

const steps = [
  'The number of uploaded contacts matches your messaging tier.',
  'There are no duplicate numbers.',
  'The contact numbers are formatted correctly (+00 00 000 0000)',
  'There is a populated field for each template variable.',
];

function BulkSending({
  valueTags,
  onChange,
  bulkSelectedItem,
  preflightData,
  downloadSampleFile,
  showWarningNotification,
}) {
  const { uploadFile, authData } = useBackend();
  const [file, setFile] = useState(null);
  const [selectedItem, setSelectedItem] = useState(options[0]);
  const [referenceElement, setReferenceElement] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [checked, setChecked] = useState(false);
  const [notification, setNotification] = useState(false);
  const toolTipWarningRef = useRef(null);

  const onSelect = (item) => {
    setSelectedItem(item);
    bulkSelectedItem(item?.id);
  };

  // const uploadFile = (lable, file) => {
  //   console.log('Lable : ', lable, file);
  //   if (!file) return;
  //   const fileName = `${file?.name.split('.')[0]}-${new Date().getTime()}`;
  //   const uploadUrl = `${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER_NAME}/${authData?.channel?.name}/${fileName}`;
  //   console.log('Upload url : ', uploadUrl);
  //   const storageRef = ref(storage, `${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER_NAME}/${fileName}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     'state_changed',
  //     (snapshot) => {
  //       const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  //       console.log('Progress : ', progress);
  //       setProgressValue(progress);
  //     },
  //     (error) => {
  //       console.log('File upload error : ', error);
  //     },
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         console.log('Download url : ', downloadURL);
  //       });
  //     }
  //   );
  // };

  const fileUpload = (lable, file) => {
    if (!file) {
      onChange('sourceFile', null);
      setFile(null);
      setProgressValue(0);
      return;
    }
    setShowLoading(true);
    setFile(file);
    let progress = 1;
    const timer = setInterval(() => {
      progress += 1;
      setProgressValue(progress);
    }, 1000);

    uploadFile(file)
      .then((res) => {
        const url = res.data.data.publicUrl;
        onChange('sourceFile', url);
        clearInterval(timer);
        setProgressValue(10);
        setShowLoading(false);
      })
      .catch((error) => {
        clearInterval(timer);
        const errorMsg = error?.response?.data;
        console.log('Dat uplaod errro : ', errorMsg);
        setProgressValue(10);
        setShowLoading(false);
      });
  };

  const onLinkClick = () => {
    const url = 'https://www.chatinc.com/getting-started-whatsapp-101';
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  };

  const fileUploadBodyContent = () => (
    <div className="grid gap-6">
      <div className="grid gap-1 justify-items-center cursor-pointer">
        <img src={excelLogo} alt="Logo" />
        <span className="text-[#1F73B7] text-[14px] font-bold">Drag files or click to upload contact sheet</span>
        <span
          className="text-[#1F73B7] text-[14px] font-normal underline"
          onClick={(e) => {
            e.stopPropagation();
            downloadSampleFile();
          }}
        >
          Download contact sheet for the selected template
        </span>
      </div>

      <div className="text-[#87929D] text-sm grid justify-items-start text-justify">
        <h2 className="font-bold">Important</h2>
        <span>1. The contact file is customized for each template you want to send.</span>
        <span>2. A new contact sheet must be downloaded, completed and uploaded for each send.</span>
      </div>

      <div className="grid justify-items-start text-justify">
        <span className="text-sm font-semibold text-[#87929D]">Please ensure: </span>
        {steps.map((item, idx) => {
          return (
            <span className="text-[#87929D] text-sm">
              {idx + 1}. {item}
            </span>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="grid gap-3">
      <Dropdown
        selectedItem={selectedItem}
        onSelect={(item) => onSelect(item)}
        downshiftProps={{ itemToString: (item) => item && item.value }}
      >
        <Field>
          <div className="flex justify-between">
            <div className="grid">
              <Label style={{ fontSize: '12px', fontWeight: '700', color: '#03363d' }}>Build your audience</Label>
              <Hint className="my-1" style={{ fontSize: '10px' }}>
                Use tags or upload a file
              </Hint>
            </div>
            <div>
              <img
                className="cursor-pointer"
                src={errorTooltip}
                alt="ToolTip Info"
                ref={toolTipWarningRef}
                onClick={() => {
                  setReferenceElement(toolTipWarningRef.current);
                }}
              />
              <TooltipModalComponent
                referenceElement={referenceElement}
                setReferenceElement={setReferenceElement}
                title={`Messaging Tier ${TIER_LIMIT.findIndex((item) => item === authData?.channel?.dailyLimit) + 1}`}
                body={
                  <div className="grid gap-2">
                    <span>
                      {`You are currently on the ${
                        TIER_LIMIT.findIndex((item) => item === authData?.channel?.dailyLimit) + 1
                      } messaging tier. This limits you to sending ${authData?.channel?.dailyLimit} contacts every 24
                      hours.`}
                    </span>
                    <span
                      className="w-fit cursor-pointer"
                      style={{ borderBottom: '1px solid' }}
                      onClick={() => onLinkClick()}
                    >
                      How do I increase my tier?
                    </span>
                  </div>
                }
                modalStyle={{ maxWidth: '250px', padding: '20px', background: '#5293C7' }}
                titleStyle={{ color: 'white' }}
                bodyStyle={{ color: 'white', marginTop: '10px' }}
                placement="start"
                hasArrow={true}
              />
            </div>
          </div>
          <Autocomplete isCompact>{selectedItem?.value}</Autocomplete>
        </Field>

        <Menu>
          {options.map((option) => (
            <Item key={option.id} value={option} hidden={option.id === 'placeholder'}>
              {option.value}
            </Item>
          ))}
        </Menu>
      </Dropdown>

      {selectedItem?.id === 'tags' && <Tags valueTags={valueTags} onChange={onChange} />}
      {selectedItem?.id === 'file' && (
        <AttachMedia
          onChange={fileUpload}
          bodyContent={fileUploadBodyContent()}
          style={{ padding: '40px 10px' }}
          showLoading={showLoading}
          progressValue={progressValue}
          contactUpload={true}
        />
      )}

      {selectedItem?.id === 'file' && progressValue === 10 && file && (
        <>
          <span style={{ fontSize: '12px', fontWeight: '700', color: '#03363d' }}>Pre-Flight Check</span>
          <div className="grif gap-1]">
            {preflightData.map((item, index) => {
              return (
                <div className="flex gap-1">
                  <img
                    src={item?.status === 'error' ? errorIcon : successIcon}
                    alt="Status"
                    className="self-start mt-[1px]"
                  />
                  <span className="text-[#2F3941]">{item.message}</span>
                </div>
              );
            })}
          </div>

          {/* <CheckBoxField style={{ marginTop: '15px' }}>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
              <CheckBoxLabel style={{ fontWeight: '400' }}>Save uploaded contacts to Zendesk?</CheckBoxLabel>
            </Checkbox>
          </CheckBoxField> */}
        </>
      )}
      {showWarningNotification && (
        <TemplateNotification
          type="error"
          title="Your campaign audience size is greater than what your current WhatsApp Business Messaging Tier allows."
          body="Proceeding will only send to the first 1000 contacts."
          setNotification={setNotification}
          notification={notification}
          titleColor="#681219"
        />
      )}
      {checked && <Tags valueTags={valueTags} onChange={onChange} />}
    </div>
  );
}

export default BulkSending;
