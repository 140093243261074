import React from 'react';
import { Alert, Title, Close } from '@zendeskgarden/react-notifications';

function TemplateNotification({
  type,
  title,
  body,
  buttons,
  setNotification,
  notification,
  closable = true,
  titleColor,
}) {
  return (
    <div>
      {notification && (
        <Alert type={type} style={{ borderRadius: '5px', boxShadow: 'none' }}>
          <Title style={{ color: titleColor ? titleColor : '#2F3941' }}>{title}</Title>
          {body}
          <Close
            aria-label="Close Notification"
            onClick={() => setNotification(false)}
            className="cursor-pointer"
            hidden={closable}
          />
        </Alert>
      )}
    </div>
  );
}

export default TemplateNotification;
