import styled from 'styled-components';
import { Button } from '@zendeskgarden/react-buttons';
import { Spinner } from '@zendeskgarden/react-loaders';
import { useNavigate } from 'react-router-dom';
import { useBackend } from '../../../api/BackendApiService';

const Sunco = ({ selectedChannel, onSuccess }) => {
  const { loading, register, clientData } = useBackend();

  const naviagte = useNavigate();
  const lastPage =
    clientData.location === 'user_sidebar' ? '/zd-user' : clientData.location === 'ticket_sidebar' ? '/zd-tickets' : -1;

  const onSubmitChannelSetup = async () => {
    let payload = {
      name: selectedChannel?.displayName,
      zendeskChannelId: selectedChannel?._id,
    };
    const res = await register(payload);
    if (res) {
      onSuccess();
      return;
    }
  };

  return (
    <div>
      <ButtonsContainer>
        <StyledButton onClick={() => naviagte(lastPage, { state: { clientData: clientData }, replace: true })}>
          Back
        </StyledButton>
        <Container>
          <SetupButton isPrimary disabled={loading} onClick={() => onSubmitChannelSetup()}>
            {loading && <Spinner />}
            <Text hidden={loading}>Submit</Text>
          </SetupButton>
        </Container>
      </ButtonsContainer>
    </div>
  );
};

export default Sunco;

const Container = styled.div`
  display: grid;
  gap: 23px;
  padding: ${(props) => (props.location === 'top_bar' ? '20px !important' : '0px')};
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
`;

const SetupButton = styled(Button)`
  padding: 10px 20px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const Text = styled.span`
  visibility: ${(props) => props.hidden && 'hidden'};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`;
