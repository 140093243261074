import React, { useState } from 'react';
import { Modal, Header, Body, Footer, FooterItem, Close } from '@zendeskgarden/react-modals';
import { Button } from '@zendeskgarden/react-buttons';
import { Field, Label, Checkbox } from '@zendeskgarden/react-forms';

function AlertNotifcation({ type, title, body, buttons, setNotification, notification, showCheckbox, checkboxText }) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  return (
    <div className="max-w-[80%]">
      {notification && (
        <Modal onClose={() => setNotification(false)} style={{ maxWidth: '80%' }}>
          <Header tag="h2" style={{ color: type === 'error' && '#CC3340' }}>
            {title}
          </Header>
          <Body>
            <span>{body}</span>
            <Field style={{ marginTop: '15px' }}>
              {showCheckbox && (
                <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
                  <Label>{checkboxText}</Label>
                </Checkbox>
              )}
            </Field>
          </Body>
          {buttons && (
            <Footer>
              {buttons?.map((button) => (
                <FooterItem>
                  <Button
                    onClick={() => {
                      button.onClick(button?.text);
                      setLoading(true);
                    }}
                    isBasic
                    style={{
                      background: button?.color && '#CC3340',
                      color: button?.color && 'white',
                      cursor: loading && 'not-allowed',
                    }}
                    disabled={loading}
                  >
                    {button?.text}
                  </Button>
                </FooterItem>
              ))}
            </Footer>
          )}
          <Close aria-label="Close modal" />
        </Modal>
      )}
    </div>
  );
}

export default AlertNotifcation;
