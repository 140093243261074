import React from 'react';
import { Field, Input } from '@zendeskgarden/react-forms';

function TemplateVariables({ variables, setVariables }) {
  const onVaraibleTextChange = (e, key) => {
    const newVariable = variables.map((variable) => {
      if (variable.hasOwnProperty(key)) {
        return { ...variable, [key]: e.target.value };
      }
      return variable;
    });
    setVariables(newVariable);
  };

  return (
    <div className="grid gap-3 py-5 px-3">
      {variables?.map((variable, idx) => (
        <div className="flex gap-3 items-center">
          <div className="w-[29px] h-[25px] text-center rounded-[25px] content-center bg-[#FFD424]">
            <span>{idx + 1}</span>
          </div>
          <Field key={idx} className="w-full">
            <Input
              isCompact
              name={`variable-${Object.keys(variable)[0]}`}
              value={Object.values(variable)[0]}
              onChange={(e) => onVaraibleTextChange(e, Object.keys(variable)[0])}
              placeholder={`Variable ${Object.keys(variable)[0]}`}
            />
          </Field>
        </div>
      ))}
    </div>
  );
}

export default TemplateVariables;
