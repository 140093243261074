import React, { useState, lazy, Suspense, memo, useEffect } from 'react';
import { Tabs, TabList, Tab, TabPanel } from '@zendeskgarden/react-tabs';
import Loader from '../components/Loader';
import { no_user } from '../api/ZendeskApiService';
import { Mixpanel } from '../components/MixPanel';
import { MixPanelAppAccess } from './send-message/constants';
import styled from 'styled-components';

const AgentActivity = lazy(() => import('../components/AgentActivity'));
const BulkSummary = lazy(() => import('../components/BulkSummary'));
const AgentSummary = lazy(() => import('../components/AgentSummary'));

const SummaryTabs = memo(() => {
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const [clientData, setClientData] = useState({ user: { role: 'admin' } });
  const isAdmin = clientData.user?.role === 'admin';

  useEffect(() => {
    async function fetchClientData() {
      const data = await no_user();
      setClientData(data);
      Mixpanel.track(MixPanelAppAccess, {
        User: data?.user?.email,
        Domain: data?.account?.subdomain,
        AppLocation: data?.location,
      });
    }
    fetchClientData();
    setSelectedTab(null);
    setSelectedTab(localStorage.getItem('selectedTab') ?? 'tab-1');
  }, []);

  return (
    <Tabs
      selectedItem={selectedTab}
      onChange={(tab) => {
        localStorage.setItem('selectedTab', tab);
        setSelectedTab(tab);
      }}
    >
      <TabList>
        <Tab item="tab-1">My Activity</Tab>
        {isAdmin && (
          <>
            <Tab item="tab-2">Agent Send Summary</Tab>
            <Tab item="tab-3">Bulk Send Summary</Tab>
          </>
        )}
      </TabList>
      <Suspense fallback={<Loader />}>
        <TabPanel item="tab-1">
          <div style={{ maxWidth: 'fit-content' }}>
            <div style={{ margin: '0px 25px' }}>{<AgentActivity myActivity={true} tab={selectedTab} />}</div>
          </div>
        </TabPanel>
        {isAdmin && (
          <>
            <TabPanel item="tab-2">
              <AgentSummary tab={selectedTab} />
            </TabPanel>
            <TabPanel item="tab-3">
              <BulkSummary tab={selectedTab} />
            </TabPanel>
          </>
        )}
      </Suspense>
    </Tabs>
  );
});

export default SummaryTabs;
