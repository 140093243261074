import React from 'react';
import { Field, Input, Label } from '@zendeskgarden/react-forms';
import { InfoTooltip } from '../tooltip';

function CampaignName({ label, templateName, onChangeTemplateName, tooltip, fontSize, color }) {
  return (
    <Field>
      <div className="grid items-center gap-1 font-bold" style={{ gridTemplateColumns: 'max-content 16px' }}>
        <Label style={{ fontSize: fontSize, fontWeight: '700', color: color }}>{label}</Label>
        {tooltip && <InfoTooltip tooltip={tooltip} />}
      </div>
      <Input
        className="mt-1"
        isCompact
        name="Template name"
        placeholder="Enter template name"
        value={templateName}
        onChange={(e) => onChangeTemplateName(e.target.value)}
      />
    </Field>
  );
}

export default CampaignName;
