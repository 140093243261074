import { useState } from 'react';

const LayoutService = () => {
  const [headerData, setHeaderData] = useState({});

  const setHeaderDataForPage = (data) => {
    setHeaderData(data);
  };

  return { headerData, setHeaderDataForPage };
};

export default LayoutService;
