import React from 'react';

import { Dropdown, Field, Menu, Item, Select, Label, Hint, Message } from '@zendeskgarden/react-dropdowns';
import { useBackend } from '../../api/BackendApiService';
import styled from 'styled-components';
import { InfoTooltip } from '../tooltip';
import { StatusIndicator } from '@zendeskgarden/react-avatars';

function Channel({ onChannelChange, selectedChannel, lable, tooltip, showAllChannelsWithInfo, fontSize, color }) {
  const { channels } = useBackend();

  return (
    <Dropdown
      selectedItem={selectedChannel}
      onSelect={(item) => onChannelChange(item)}
      downshiftProps={{ itemToString: (item) => item && item }}
    >
      <Field>
        <SelectLabelContainer>
          {lable && (
            <SelectLabel fontSize={fontSize} color={color}>
              {lable}
            </SelectLabel>
          )}
          {tooltip && <InfoTooltip tooltip={tooltip} />}
        </SelectLabelContainer>

        <Select isCompact disabled={false}>
          {selectedChannel ? selectedChannel?.displayName : 'Select channel'}
        </Select>
        {showAllChannelsWithInfo && (
          <Hint>
            {!!selectedChannel && selectedChannel?.isSetup && (
              <Message style={{ fontSize: '14px' }} validation="success">
                Channel successfully set up
              </Message>
            )}
          </Hint>
        )}
      </Field>

      <Menu>
        {channels ? (
          <>
            {showAllChannelsWithInfo ? (
              <>
                {channels.map((option) => {
                  const type = option.isSetup ? 'available' : 'offline';
                  return (
                    <Item key={option._id} value={option}>
                      <StatusIndicator type={type} aria-label={`status: ${type}`}>
                        {`${option.displayName} ${option.phoneNumber ? '+' : ''}${option.phoneNumber}`}
                      </StatusIndicator>
                    </Item>
                  );
                })}
              </>
            ) : (
              <>
                {channels
                  .filter((ele) => ele.isSetup)
                  .map((option) => (
                    <Item key={option._id} value={option}>
                      {option?.name}
                    </Item>
                  ))}
              </>
            )}
          </>
        ) : (
          <Item disabled>Loading...</Item>
        )}
      </Menu>
    </Dropdown>
  );
}

const SelectLabel = styled(Label)`
  color: ${(props) => props?.color && props?.color};
  font-size: ${(props) => (props?.fontSize ? props?.fontSize : '12px')};
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

export default Channel;
