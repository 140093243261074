import { SUBCRIPTION_TYPE } from '../pages/send-message/constants';

// To calculate days remaining before trial expier
export const calculateDaysBeforeTrial = (authData, diffDays) => {
  if (authData && !authData?.auth?.fullAccess && !diffDays) {
    let currentDate = new Date();
    let expiryDate = new Date(authData?.activatedOn);
    expiryDate.setDate(expiryDate.getDate() + parseInt(process.env.REACT_APP_TRIAL_PERIOD));
    const diffTime = expiryDate.getTime() - currentDate.getTime();
    let diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffInDays < 0) diffInDays = 0;
    return diffInDays.toString();
  }
};

export const showDaysLeft = (authData) => {
  if (authData?.auth?.fullAccess || !authData?.channel) return false;
  if (!authData?.subscription) return true;
  if (authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
    return true;
  return false;
};
