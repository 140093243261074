import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateDaysBeforeTrial, showDaysLeft } from '../Utilities/trialChecker';
import { useBackend } from '../api/BackendApiService';
import TemplateNotification from '../pages/template-wizards/notification/notification';

const homeImg = '/asset/home.svg';
const settingImg = '/gear-stroke.svg';
const playCircle = '/asset/play_circle.svg';

function Header({ type, title, body, showVideoLink, pageName, timer }) {
  const { authData, languages, clientData } = useBackend();
  const [notification, setNotification] = useState(!!title);
  const [diffDays, setDiffDays] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setDiffDays(calculateDaysBeforeTrial(authData, diffDays));
    if (timer)
      setTimeout(() => {
        setNotification(null);
      }, timer);
  }, [timer]);

  return (
    <div className="grid mb-5">
      <div className="flex justify-between bg-[#DBEAFE] h-12 items-center">
        <div className="flex ml-2 gap-2">
          <div
            className="bg-white p-2 rounded-[7px] h-8 w-8 cursor-pointer"
            onClick={() => {
              if (!!languages?.length && clientData?.location === 'top_bar') {
                navigate('/template-viewer', {
                  state: { tab: 'sendMessageTab' },
                });
              }
            }}
          >
            <img src={homeImg} alt="Home" />
          </div>
          <span className="text-[14px] font-[800] self-center text-[#2F3941]">
            {authData?.subscription?.plan?.name?.replace('Chat Inc', '')?.trim()}
          </span>
        </div>

        <div className="flex gap-2 items-center font-medium">
          {showDaysLeft(authData) && authData && (
            <span className="text-sm text-[#ff0006]">{`Trial ${diffDays} ${
              +diffDays > 1 ? 'Days left' : 'Day left'
            }`}</span>
          )}
          {clientData?.user?.role === 'admin' && (
            <div className="bg-white p-2 rounded-[7px] mr-2 h-8 w-8">
              <img
                src={settingImg}
                alt="Setting"
                className="h-4 w-4 cursor-pointer"
                onClick={() =>
                  navigate('/settings', {
                    state: { clientData: clientData },
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
      {title && (
        <TemplateNotification
          type={type}
          title={title}
          body={body}
          setNotification={setNotification}
          notification={notification}
        />
      )}
      {(pageName || showVideoLink) && (
        <div className="flex justify-between mt-7 mx-3">
          {pageName && <span className="text-[24px] font-bold">{pageName}</span>}
          {showVideoLink && (
            <div className="flex gap-1 cursor-pointer">
              <img src={playCircle} alt="Play Circle" />
              <span className="text-[#1F73B7] underline">Watch (00:32)</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
