import { PaymentElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { Button } from '@zendeskgarden/react-buttons';
import { Inline } from '@zendeskgarden/react-loaders';
import { useBackend } from '../../api/BackendApiService';
import Agent from '../agents';
import { Mixpanel } from '../../components/MixPanel';
import { MixPanelPaymentFailed, MixPanelPaymentSuccess } from '../send-message/constants';

const imgPlaceholder = '/payment_success1.jpg';

export default function CheckoutForm({
  selectedPlan,
  userCount,
  onClickBack,
  adminCount,
  recurrenceType,
  onBoarding,
  currentUser,
  authData,
}) {
  const { setNotification } = useBackend();
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showAgents, setShowAgents] = useState(false);
  const prices = selectedPlan?.prices?.find((item) => item.recurrenceType === recurrenceType);

  useEffect(() => {
    let amount = 0;
    if (onBoarding) {
      amount =
        prices?.amount +
        prices?.perUserPrice * userCount +
        prices?.perAdminPrice * adminCount +
        selectedPlan?.onboardingAmount +
        selectedPlan?.onboardingAdminPrice * adminCount +
        selectedPlan?.onboardingUserPrice * userCount;
    } else {
      amount = prices?.amount + prices?.perUserPrice * userCount + prices?.perAdminPrice * adminCount;
    }
    setAmount(amount);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    stripe
      .confirmPayment({
        elements,
        confirmParams: {},
        redirect: 'if_required',
      })
      .then((res) => {
        if (res.error) {
          setLoading(false);
          Mixpanel.track(MixPanelPaymentFailed, {
            Domain: authData?.auth?.domain,
            User: currentUser?.email,
            Plan: selectedPlan.name,
            SubscriptionType: 'Subscription',
            Error: res.error.message,
          });
          const notificationObject = {
            title: 'Payment Failed',
            description: res.error.message,
            mode: 'error',
            hideClose: false,
          };
          setNotification(notificationObject);
        } else {
          setLoading(false);
          setIsSuccess(true);
          Mixpanel.track(MixPanelPaymentSuccess, {
            Domain: authData?.auth?.domain,
            User: currentUser?.email,
            Plan: selectedPlan.name,
            SubscriptionType: 'Subscription',
          });
        }
      });
  };

  if (isSuccess) {
    return (
      <div style={{ display: 'grid', justifyContent: 'center' }}>
        <StyledImage alt="" src={imgPlaceholder} />
        <PaymentSpan>Payment Successfull</PaymentSpan>
        <MessageSpan>Your payment has been submitted successfully</MessageSpan>
        <StyledButton
          isPrimary
          onClick={() => {
            setShowAgents(true);
            setIsSuccess(false);
          }}
        >
          Continue to App
        </StyledButton>
      </div>
    );
  }

  if (showAgents) return <Agent payment={true} />;

  return (
    <div>
      <StyledSpan style={{ marginLeft: '20px' }}>Payment</StyledSpan>
      <HorizontalLine></HorizontalLine>
      <div style={{ marginLeft: '20px', display: 'grid' }}>
        <StyledSpan>Enter your card details.</StyledSpan>
        <br></br>
        <StyledSpan>Your subscription will start immediately after successful payment </StyledSpan>
        <br></br>
        <br></br>
        <BodySpan>{`-> Total due now is $ ${amount}`}</BodySpan>
        <br></br>
        <BodySpan>{`-> Subscribing to ${selectedPlan?.name}`}</BodySpan>
        <br></br>
      </div>
      <PaymentContainer>
        <PaymentElement id="payment-element" />
      </PaymentContainer>
      <ButtonsContainer>
        <StyledButton onClick={() => onClickBack()}>Back</StyledButton>

        <Container>
          <StyledButton isPrimary onClick={() => handleSubmit()} disabled={loading}>
            {loading && <Inline />}
            <span hidden={loading}>Subscribe</span>
          </StyledButton>
        </Container>
      </ButtonsContainer>
    </div>
  );
}

const PaymentContainer = styled.div`
  padding: 20px;
  margin: 20px 0;
  width: 500px;
`;

const Container = styled.div`
  display: grid;
  gap: 23px;
`;

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #03363d;
`;

const HorizontalLine = styled.hr`
  margin-top: 22px;
  height: 1px;
  border-top: 0.25px solid #eff0f1;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin: 0 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 15px;
`;

const BodySpan = styled.span`
  font-szie: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #03363d;
`;

const PaymentSpan = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #03363d;
  text-align: center;
`;

const MessageSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #03363d;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  height: 200px;
  justify-self: center;
`;
