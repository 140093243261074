import React from 'react';
import { Field, Input } from '@zendeskgarden/react-forms';

const closeImg = '/asset/close.svg';

function TemplateButtons({ buttons, setButtons, showBackground }) {
  const onButtonTextChange = (e, idx) => {
    const newButtons = buttons.map((button, index) => {
      if (index === idx) {
        return { ...button, text: e.target.value };
      }
      return button;
    });
    setButtons(newButtons);
  };

  return (
    <div className={`grid gap-3 ${showBackground ? 'pb-5 px-3' : 'mx-3'}`}>
      {buttons.map((button, idx) => (
        <Field key={idx}>
          <div className="flex border-solid border-[1px] rounded-[3px] border-[#C2C8CC] justify-center px-2 h-8">
            <Input
              isCompact
              name={`Button-${idx}`}
              value={button.text}
              onChange={(e) => onButtonTextChange(e, idx)}
              isBare
              placeholder={`Button ${idx + 1}`}
            />
            <img
              src={closeImg}
              alt="Close"
              className="cursor-pointer p-1"
              onClick={() => {
                const newButtons = buttons.filter((_, buttonIndex) => buttonIndex !== idx);
                setButtons(newButtons);
              }}
            />
          </div>
        </Field>
      ))}
    </div>
  );
}

export default TemplateButtons;
