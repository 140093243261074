import React, { useEffect, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import { EditorState, RichUtils, getDefaultKeyBinding, Modifier } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import '@draft-js-plugins/emoji/lib/plugin.css';
import TemplateButtons from '../template-buttons';
import { MAX_BUTTONS, MAX_MESSAGE_LENGTH, MAX_VARIABLES } from '../../template-wizards/constant';
import TemplateVariables from '../template-variables';
import { addCustomTag, parseHtmlText, removeXAfterPlaceholders } from '../../send-message/template/templateAdapter';
import {
  addVariable,
  getCursorPositionAndText,
  getVariablesFromText,
  onBackspaceClick,
  removeVariable,
} from './textEditorAdapter';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import TooltipModalComponent from '../../../components/tooltip/TooltipModal';

const underlineImg = '/asset/underline.svg';
const boldImg = '/asset/bold.svg';
const italicImg = '/asset/italic.svg';
const emojiIcon = '/asset/emoji.svg';

/**
 * @param {String} htmlText - accepts html in string format
 * @returns editorState
 */
const getEditorState = (htmlText) => {
  let text = (htmlText || '').replace(/{{(.*?)}}/g, function replacer(m) {
    return `<s>${m} x</s>`;
  });
  const editorState = EditorState.createWithContent(convertFromHTML(text));
  return editorState;
};

const styleMap = {
  STRIKETHROUGH: {
    border: '1px solid #FFD424',
    borderRadius: 0,
    backgroundColor: '#FFD424',
    padding: '1px 4px',
    cursor: 'pointer',
  },
  IALIC: {
    textDecoration: 'line-through',
    cursor: 'pointer',
  },
};

function TextEditor({ templateText, selectedTemplate, updateSelectedTemplate }) {
  const [editorState, setEditorState] = useState(getEditorState(templateText));
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const [buttons, setButtons] = useState([]);
  const [variables, setVariables] = useState([]);

  const emojiBtnRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState();

  const editorRef = useRef();

  useEffect(() => {
    setEditorState(
      getEditorState(
        templateText
          .split('\n')
          .map((line) => `<p>${line}</p>`)
          .join('')
      )
    );
    const buttonsComponent = selectedTemplate.components.find((component) => component.type === 'BUTTONS');
    setButtons(buttonsComponent?.buttons ? buttonsComponent?.buttons : []);
    let varArray = [];
    const bodyComponent = selectedTemplate?.components.find((ele) => ele?.type === 'BODY');
    for (let i = 0; i < selectedTemplate?.variables?.body; i++) {
      varArray.push({ [i + 1]: bodyComponent?.example?.body_text[0][i] });
    }
    setVariables(varArray);
  }, [selectedTemplate, templateText]);

  useEffect(() => {
    const htmlText = removeXAfterPlaceholders(getHTMLText());
    updateVariables(htmlText);
    setTextLength(parseHtmlText(htmlText).length);
    editorState.getCurrentInlineStyle().has('BOLD') ? setBold(true) : setBold(false);
    editorState.getCurrentInlineStyle().has('ITALIC') ? setItalic(true) : setItalic(false);
    editorState.getCurrentInlineStyle().has('UNDERLINE') ? setUnderline(true) : setUnderline(false);
    updateSelectedTemplate('BODY', htmlText);
  }, [editorState]);

  const checkVariablesEquality = (remainingVariable) => {
    return (
      remainingVariable.length === variables.length &&
      variables.every((obj) => {
        const key = Object.keys(obj)[0];
        return remainingVariable.includes(parseInt(key));
      })
    );
  };

  useEffect(() => {
    updateSelectedTemplate('BUTTONS', buttons);
  }, [buttons]);

  useEffect(() => {
    updateSelectedTemplate('variables', variables);
  }, [variables]);

  const updateVariables = (htmlText) => {
    const remainingVariable = getVariablesFromText(htmlText);
    if (!!variables?.length && !checkVariablesEquality(remainingVariable)) {
      if (remainingVariable?.length < variables?.length)
        setVariables(variables?.filter((ele) => remainingVariable?.includes(parseInt(Object.keys(ele)[0]))));
      else if (remainingVariable.length > variables.length) {
        const newArray = [...variables];
        remainingVariable.forEach((value) => {
          if (!newArray.some((obj) => Object.keys(obj)[0] === value.toString())) {
            const newObj = {};
            newObj[value] = '';
            newArray.push(newObj);
          }
        });
        setVariables(newArray);
      }
    }
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
  };

  /**
   * Handles key board commands
   * @param {string} command - command action string
   * @param {EditorState} es - current editor State
   */
  const handleKeyCommand = (command, es) => {
    if (command === 'backspace') {
      const { variableNumber, newEditorState } = onBackspaceClick(editorState);
      if (newEditorState !== editorState && variableNumber > 0) {
        reorderVariables(variableNumber, newEditorState);
        focusOnEditor();
        return 'handled';
      }
    }
    command === 'bold'
      ? setBold(!bold)
      : command === 'italic'
      ? setItalic(!italic)
      : command === 'underline' && setUnderline(!underline);
    //  Handle BOLD, ITALIC and underline
    if (['bold', 'italic', 'underline'].includes(command)) {
      const newState = RichUtils.handleKeyCommand(es, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }

      return 'not-handled';
    }

    if (command === 'STRIKETHROUGH') {
      const newState = RichUtils.handleKeyCommand(es, 'code');
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }

      return 'not-handled';
    }
  };

  // this function is used to get key events
  const keyBindingFn = (e) => {
    if (e.keyCode === 8 && !e.shiftKey) {
      // 8 is the keyCode for backspace
      return 'backspace';
    }
    return getDefaultKeyBinding(e);
  };

  const getHTMLText = (newEditorSate = null) => {
    const contentState = newEditorSate ? newEditorSate.getCurrentContent() : editorState.getCurrentContent();
    if (contentState) {
      const htmlText = convertToHTML({
        entityToHTML: (entity, originalText) => {
          return originalText;
        },
      })(contentState);
      let text = htmlText;
      text = text.replace(/<s>(.*?)<\/s>/g, function (s, p1) {
        const str = p1.split(' ');
        let txt = '';
        for (let i = 0; i < str.length; i++) {
          txt = txt + str[i].toLowerCase();
        }
        return `{{${txt}}}`;
      });
      return text;
    }

    return <p></p>;
  };

  const onAddExtraItem = (itemType) => {
    if (itemType === 'button' && buttons.length < MAX_BUTTONS) {
      const newButtons = [...buttons, { type: 'QUICK_REPLY', text: '' }];
      setButtons(newButtons);
    } else if (itemType === 'variable' && variables.length < MAX_VARIABLES) {
      let state;
      if (!!variables.length) {
        state = addCustomTag({ editorState, text: `{{${variables.length + 1}}} x` });
      } else {
        state = addCustomTag({ editorState, text: `{{1}} x` });
      }
      const variablesInText = getVariablesFromText(getHTMLText(state));
      const newVariables = variablesInText.map((key, index) => {
        const obj = variables.find((item) => key in item);
        return { [index + 1]: obj ? obj[key] : '' };
      });
      setVariables(newVariables);
      const text = addVariable(parseHtmlText(getHTMLText(state)).text);
      const newEditorState = getEditorState(removeXAfterPlaceholders(text));
      setEditorState(newEditorState);
    }
  };

  const focusOnEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const onDeleteVariable = () => {
    const { cursorPosition, text } = getCursorPositionAndText(editorState);
    if (text[cursorPosition] === 'x' || text[cursorPosition - 1] === 'x') {
      const { variableNumber, newEditorState } = onBackspaceClick(editorState);
      if (newEditorState !== editorState && variableNumber > 0) {
        reorderVariables(variableNumber, newEditorState);
      }
    }
  };

  const reorderVariables = (variableNumber, newEditorState) => {
    const text = removeVariable(variableNumber, parseHtmlText(getHTMLText(newEditorState)).text);
    let updatedVariables = variables?.filter((ele) => !(variableNumber in ele));
    updatedVariables = updatedVariables.map((item, index) => ({ [index + 1]: Object.values(item)[0] }));
    setVariables(updatedVariables);
    const editorState = getEditorState(removeXAfterPlaceholders(text));
    setEditorState(editorState);
  };

  const renderEditorButtons = (img, command) => {
    return (
      <div
        className={`w-[40px] flex justify-center ${
          command === 'bold' && bold
            ? 'bg-[#E5E7EB]'
            : command === 'italic' && italic
            ? 'bg-[#E5E7EB]'
            : command === 'underline' && underline && 'bg-[#E5E7EB]'
        } cursor-pointer text-[25px] items-center font-[200]`}
        onClick={() => handleKeyCommand(command, RichUtils.toggleInlineStyle(editorState, command))}
      >
        <span className={`text-[#68737D] ${command === 'italic' && 'italic'}`}>
          {command === 'bold' ? 'B' : command === 'italic' ? 'I' : 'U'}
        </span>
      </div>
    );
  };

  const addEmoji = (emoji) => {
    if (editorState) {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const contentStateWithEmoji = Modifier.replaceText(contentState, selectionState, emoji.native);
      const newEditorState = EditorState.push(editorState, contentStateWithEmoji, 'insert-characters');
      setEditorState(newEditorState);
    }
  };

  return (
    <div className="grid gap-5 justify-self-center">
      <div className="grid max-h-[300px] min-h-[300px] max-w-[470px] rounded border-[1px] border-solid border-[#E5E7EB] mb-5 content-center mx-3">
        <div
          className="max-h-[250px] min-h-[250px] p-3 border-t-0 border-x-0 border-b-[1px] border-solid border-b-[#E5E7EB] cursor-text"
          onClick={focusOnEditor}
        >
          <div className="flex overflow-auto min-h-[210px] max-h-[210px]" onClick={(e) => onDeleteVariable()}>
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={(e) => onChange(e)}
              handleKeyCommand={handleKeyCommand}
              placeholder="Write your message"
              customStyleMap={styleMap}
              keyBindingFn={keyBindingFn}
            />
          </div>
          <div className="flex justify-end">
            <span className="text-[#68737D] self-center text-sm text-end -mb-5">
              {textLength}/{MAX_MESSAGE_LENGTH}
            </span>
          </div>
        </div>
        <div className="flex h-[50px]">
          {renderEditorButtons(boldImg, 'bold')}
          {renderEditorButtons(italicImg, 'italic')}
          {renderEditorButtons(underlineImg, 'underline')}

          <div
            className="flex items-center emoji-btn cursor-pointer w-[43px] justify-center"
            ref={emojiBtnRef}
            onClick={() => {
              setReferenceElement(emojiBtnRef.current);
            }}
          >
            <img src={emojiIcon} alt="Emoji" width="20px" />
          </div>

          <TooltipModalComponent
            referenceElement={referenceElement}
            setReferenceElement={setReferenceElement}
            body={<Picker data={data} onEmojiSelect={addEmoji} darkMode={false} theme="light" />}
            modalStyle={{ width: 'auto', padding: '0' }}
            bodyStyle={{ padding: '0' }}
            placement="auto"
            hasArrow={false}
          />

          <div
            className="flex gap-1 justify-center items-center px-5 my-1 border-x-2 border-y-0 border-solid border-[#E9EBED] ml-4 cursor-pointer"
            onClick={() => onAddExtraItem('variable')}
          >
            <span className="text-2xl mb-1">+</span>
            <span className="text-[#68737D] self-center font-[300]">Add variable</span>
          </div>
          <div
            className="flex gap-1 justify-center items-center ml-4 cursor-pointer"
            onClick={() => onAddExtraItem('button')}
          >
            <span className="text-2xl mb-1">+</span>
            <span className="text-[#68737D] self-center font-[300]">Add Button</span>
          </div>
        </div>
      </div>
      {!!variables?.length && (
        <div className="grid bg-[#F8F9F9]">
          <div className="flex justify-between px-3 pt-5 ">
            <span className="text-[14px] font-bold text-[#2F3941]">Enter variable sample value</span>
          </div>
          <TemplateVariables variables={variables} setVariables={setVariables} />
        </div>
      )}
      {!!buttons.length && (
        <div className={`grid ${!variables.length && 'bg-[#F8F9F9]'}`}>
          <div className={`flex justify-between ${!variables.length ? 'px-3 pt-5' : 'mb-2 mx-3'}`}>
            <span className="text-[14px] font-bold text-[#2F3941]">Button Text</span>
            <span className="text-[#68737D] text-sm">
              Buttons {buttons?.length}/{MAX_BUTTONS}
            </span>
          </div>
          <TemplateButtons buttons={buttons} setButtons={setButtons} showBackground={!variables.length} />
        </div>
      )}
    </div>
  );
}

export default TextEditor;
