import React, { useState, useEffect } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { Tooltip } from '@zendeskgarden/react-tooltips';

const prevBtn = '/asset/circular-back.svg';
const forwardBtn = '/asset/circular-forward.svg';

function TemplateCarousel({ selectedTemplate, onTemplateSelect, sampleTemplates }) {
  const [visibleTemplates, setVisibleTemplates] = useState(sampleTemplates?.slice(0, 3));

  useEffect(() => {
    setVisibleTemplates(sampleTemplates?.slice(0, 3));
  }, [sampleTemplates]);

  const handlePrevious = () => {
    setVisibleTemplates((prevButtons) => {
      let first = sampleTemplates?.indexOf(prevButtons[0]) - 1;
      if (first < 0) first = sampleTemplates?.length - 1;
      return [sampleTemplates[first], ...prevButtons.slice(0, 2)];
    });
  };

  const handleNext = () => {
    setVisibleTemplates((prevButtons) => {
      let last = sampleTemplates?.indexOf(prevButtons[2]) + 1;
      if (last === sampleTemplates?.length) last = 0;
      return [...prevButtons.slice(1, 3), sampleTemplates[last]];
    });
  };

  return (
    <div className="flex justify-center gap-3 max-w-[470px] justify-self-center items-center">
      <img src={prevBtn} onClick={handlePrevious} aria-label="Previous" className="cursor-pointer mt-1" />

      {visibleTemplates.map((template, index) => {
        const backgroundColor = selectedTemplate?.name === template?.name && '#CEE2F2';
        return (
          <Tooltip content={template?.friendlyName || template?.name}>
            <Button
              key={index}
              className="rounded-[10px] w-[110px]"
              style={{ background: backgroundColor }}
              onClick={() => onTemplateSelect(template)}
              data-tooltip={template?.name}
            >
              <span className="overflow-hidden whitespace-nowrap block text-ellipsis">
                {template?.friendlyName || template?.name}
              </span>
            </Button>
          </Tooltip>
        );
      })}

      <img src={forwardBtn} onClick={handleNext} aria-label="Next" className="cursor-pointer mt-1" />
    </div>
  );
}

export default TemplateCarousel;
