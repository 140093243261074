import React, { useEffect } from 'react';
import { Button } from '@zendeskgarden/react-buttons';
import { useNavigate } from 'react-router-dom';
import { useBackend } from '../../api/BackendApiService';

const EmptyFolderImg = '/asset/empty-folder.svg';

function TemplateWizard({ layoutService }) {
  const { clientData } = useBackend();
  const navigate = useNavigate();

  useEffect(() => {
    layoutService.setHeaderDataForPage({
      clientData,
      type: 'info',
      title: 'Important',
      body: 'Templates can take up to an hour to be approved by Meta',
      location: 'templateWizard',
    });
  }, []);

  return (
    <div className="grid max-w-[500px] -mx-1">
      <div className="grid gap-3 mx-3 mt-4">
        <span className="text-[14px]">It's time to send your first message</span>
        <div className="flex gap-5 justify-center items-center flex-col mt-6">
          <img src={EmptyFolderImg} alt="Empty Folder" />
          <span className="text-[14px] text-[#A9A9A9]">Oops, we see you don’t have any templates yet.</span>
        </div>
        <div className="flex items-center justify-end mt-9 mb-7">
          <Button isPrimary size="large" onClick={() => navigate('/create-template')}>
            Create New
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TemplateWizard;
