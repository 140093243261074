import React from 'react';
import Header from './header';
import { useBackend } from '../api/BackendApiService';
import Notification from '../components/notification';

const locations = ['ticket_sidebar', 'user_sidebar', 'top_bar'];

function Layout({ children, headerData }) {
  const { notification } = useBackend();
  const { type, title, body, showVideoLink, pageName, timer, clientData } = headerData;

  if (notification)
    return (
      <div className="grid place-items-center flex-1 h-full">
        <Notification />
      </div>
    );

  return (
    <div className="h-full">
      {clientData && locations.includes(clientData.location) && (
        <Header type={type} title={title} body={body} showVideoLink={showVideoLink} pageName={pageName} timer={timer} />
      )}
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { clientData });
      })}
    </div>
  );
}

export default Layout;
