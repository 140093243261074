function formatToWA(text) {
  return (
    text
      ?.replace(/\*(.+?)\*/g, '<b>$1</b>')
      ?.replace(/_(.+?)_/g, '<i>$1</i>')
      ?.replace(/~(.+?)~/g, '<u>$1</u>')
      ?.replace(/~(.+?)~/g, '<s>$1</s>')
      //custom regexp match: ```$2```
      ?.replace(/(```)([\S\s]+?)(```)/g, '<code>$2</code>')
  );
}

export { formatToWA };
