import { Spinner } from '@zendeskgarden/react-loaders';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useBackend } from '../api/BackendApiService';
import {
  checkWhatsappAllowedUser,
  getUserData,
  getUserPhonesFx,
  no_user,
  openPaymentModal,
  openZendeskModal,
  shared,
  zafClient,
} from '../api/ZendeskApiService';
import SendMessageUi from './send-message';
import moment from 'moment';
import { SUBCRIPTION_TYPE } from './send-message/constants';

function CommonPage({ layoutService }) {
  const InvoiceDueDay = process.env.REACT_APP_INVOICE_DUE_DAYS;
  const location = useLocation();
  const page = location.pathname;
  const navigate = useNavigate();
  const {
    authData,
    getChannels,
    userLogin,
    getTags,
    getLanguages,
    setChannels,
    channels,
    setNotification,
    getTemplates,
    languages,
    tags,
    setClientData,
    clientData,
    getAppTemplates,
  } = useBackend();
  const [loading, setLoading] = useState(false);
  const [appTemplates, setAppTemplates] = useState(null);

  useEffect(() => {
    if (authData) getClientData();
  }, [authData]);

  useEffect(() => {
    zafClient.on('reload', () => {
      userLogin();
      localStorage.removeItem('lastVisitedPage');
    });
  }, []);

  useEffect(() => {
    redirectUser();
  }, [languages, appTemplates]);

  const redirectUser = async () => {
    if (languages && appTemplates && page === '/zd-modal') {
      setLoading(true);
      !!languages?.length || !!appTemplates?.length
        ? navigate('/template-viewer', {
            state: { clientData, tab: 'sendMessageTab' },
          })
        : navigate('/template-wizard', {
            state: { clientData },
          });
      setLoading(true);
    }
  };

  async function getClientData() {
    page === '/zd-modal'
      ? shared.resizeFx({
          width: '500px',
          height: '500px',
        })
      : shared.resizeFx({
          width: '100%',
          height: '500px',
        });
    let clientData = null;
    if (!(await checkWhatsappAllowedUser())) {
      const notificationObject = {
        title: 'Not Allowed',
        description:
          'You do not have the correct permissions to send WhatsApp Messages. Please contact your administrator',
        mode: 'success',
        hideClose: true,
      };
      setNotification(notificationObject);
      return;
    }
    page === '/zd-user'
      ? (clientData = getUserData())
      : page === '/zd-tickets'
      ? (clientData = getUserPhonesFx())
      : (clientData = no_user());
    clientData.then((clientRes) => {
      setClientData(clientRes);
      let dueDate = moment(authData?.subscription?.startAt).add(InvoiceDueDay, 'days');
      if (
        authData?.subscription?.type === SUBCRIPTION_TYPE.invoice &&
        moment().isAfter(dueDate) &&
        authData?.subscription?.status !== 'paid' &&
        !authData?.auth?.fullAccess
      ) {
        showPaymentPendingNotification(clientRes, dueDate);
        return;
      }
      fetchChannels(clientRes);
    });
  }

  const fetchChannels = async (clientRes) => {
    getChannels()
      .then(async (res) => {
        setChannels(res.channels);
        if (res?.channels?.length === 0) {
          const notificationObject = {
            title: 'No channels available.',
            description:
              clientRes?.user && clientRes?.user.role === 'admin'
                ? 'Please follow the instructions below to set up your messaging channels.'
                : 'Please contact your Zendesk account admin to follow the installation instructions below.',
            mode: 'info',
            hideClose: true,
            button: {
              text: 'Instructions',
              url: 'https://www.zendesk.com/marketplace/apps/support/115221/dropbox/?queryID=cbdeb706c9426173bf087867db109280',
            },
          };
          setNotification(notificationObject);
        } else if (!res.channels.find((channel) => channel.isDefault)) {
          let button = null;
          if (clientRes?.user && clientRes?.user?.role === 'admin') {
            navigate('/settings', {
              replace: true,
            });
            return;
          }
          const notificationObject = {
            title: 'Setup not complete',
            description:
              clientRes?.user && clientRes?.user.role === 'admin'
                ? 'Please follow the prompts to set up your messaging channels.'
                : 'Please contact your admin to complete the installation from the side bar.',
            button: button,
            mode: 'info',
            hideClose: () => {
              return clientRes?.user && clientRes?.user === 'admin' ? false : true;
            },
          };
          setNotification(notificationObject);
        } else {
          const [language, tags, templates, appTemplates] = await Promise.all([
            getLanguages(authData?.channel?.channelId),
            getTags(),
            getTemplates(authData?.channel?.templateDefaultLanguage),
            page === '/zd-modal' ? await getAppTemplates(authData?.channel?.id) : null,
          ]);
          setAppTemplates(appTemplates);
        }
        layoutService.setHeaderDataForPage({ clientData: clientRes });
      })
      .catch((error) => {
        let description = 'Check your internet connection and refresh widget to try again';
        let title = 'Couldn’t get Channels';
        let button = null;
        if (error.status === 402) {
          title = 'Trial Expired';
          description = 'Please buy a license to continue';
          button = { text: 'Buy', onClick: () => openPaymentModal() };
          if (error.responseJSON.data) {
            showPaymentPendingNotification(clientRes, error.responseJSON.data.dueDate);
            return;
          }
        } else if (error.status === 401 || error.status === 403) {
          description = error.responseJSON.message;
          if (error.responseJSON.code === 'AccessDenied') {
            title = 'Unable to access';
            description = `Oops! Seems like you do not have access for Chat Inc Proactive. Please request your admin (${error.responseJSON.data.adminEmail}) to grant you access.`;
            if (clientRes?.role === 'admin') {
              description = 'Oops! Seems like you do not have access for Chat Inc Proactive.';
              button = { text: 'Manage Agents', onClick: () => openZendeskModal() };
            }
          }
        } else if (error.status < 500) {
          description = error.responseJSON.message;
        } else description = 'Internal Server Error! Please report this error to ChatInc @ zendesk@chatinc.com.';
        const notificationObject = {
          title: title,
          description: `${description} (${authData?.auth?.domain})`,
          button: button,
          mode: 'error',
          hideClose: true,
        };
        setNotification(notificationObject);
      });
  };

  const showPaymentPendingNotification = (clientData, dueDate) => {
    let button = null;
    if (clientData?.user.id === authData?.auth?.adminId)
      button = { text: 'Complete Payment', onClick: () => openPaymentModal(SUBCRIPTION_TYPE.invoice) };
    const notificationObject = {
      title: 'Payment Pending',
      description: `Your invoice payment is pending and was due ${moment(dueDate).format(
        'YYYY-MM-DD'
      )}. Please make payment to avoid subscription expiration (${authData?.auth?.domain})`,
      button: button,
      mode: 'error',
      hideClose: true,
    };
    setNotification(notificationObject);
  };

  if (!authData || !clientData || !channels?.length || !languages || !tags || loading)
    return (
      <CenterPageContainer>
        <Spinner size="60" color="#1f73b7" />
      </CenterPageContainer>
    );

  return <SendMessageUi />;
}

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
  height: 100%;
`;

export default CommonPage;
