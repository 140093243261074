import { useEffect, useState } from 'react';
import {
  getMessageType,
  highlightPlaceholders,
  replateTextWithVarsValue,
} from '../../pages/send-message/template/templateAdapter';
import styled from 'styled-components';
import { formatToWA } from '../../Utilities/format';
import { MessageImg } from './image';
import MessageButtons from './buttons';

const closeImg = '/asset/close.svg';

const TemplatePreview = (props) => {
  const { setTemplateText, selectedTemplate, showHeader } = props;
  const [template, setTemplate] = useState(null);
  const [textAsHTML, setTextAsHTML] = useState(null);

  useEffect(() => {
    setPreviewText();
  }, [selectedTemplate]);

  const setPreviewText = () => {
    const replyTypeData = getMessageType(props);
    setTemplate(replyTypeData);
    let htmlText = formatToWA(replateTextWithVarsValue(replyTypeData?.content?.text, replyTypeData?.vars));
    if (props?.highlightTags)
      htmlText = highlightPlaceholders(
        formatToWA(replateTextWithVarsValue(replyTypeData?.content?.text, replyTypeData?.vars))
      );
    setTextAsHTML(htmlText);
    setTemplateText && setTemplateText(htmlText);
  };

  return (
    <>
      {template !== null ? (
        <Container className="py-[55px] px-3">
          {showHeader && (
            <div className="flex justify-between w-full -mt-[50px]">
              <span className="text-[#68737D]">{selectedTemplate?.name}</span>
              <img src={closeImg} alt="Close" onClick={() => props?.onCloseClick()} className="cursor-pointer" />
            </div>
          )}
          {['text', 'quickReply'].includes(template?.type) ? (
            <>
              {!!template.content.media ? (
                <Message isButtons={!template.content.buttons?.length} className={`${showHeader && 'mt-6'}`}>
                  <MessageImg template={template} />
                  <MessageText dangerouslySetInnerHTML={{ __html: textAsHTML }} />
                </Message>
              ) : (
                <Message
                  dangerouslySetInnerHTML={{ __html: textAsHTML }}
                  isButtons={!template.content.buttons?.length}
                  className={`${showHeader && 'mt-6'}`}
                />
              )}
            </>
          ) : (
            <Message isButtons={!template.content.buttons?.length}>
              {' '}
              className={`${showHeader && 'mt-6'}`}
              {!!template.content.media && <MessageImg template={template} />}
              <MessageText dangerouslySetInnerHTML={{ __html: textAsHTML }} />
            </Message>
          )}
          {template.type !== 'text' && (
            <MessageButtons buttons={template.content.buttons} isQR={false} highlightTags={props?.highlightTags} />
          )}
        </Container>
      ) : null}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  overflow-y: scroll;
  max-width: 500px;
  background: rgba(235, 226, 222, 0.7);
  border: 1px solid #d8dcde;
  border-radius: 4px;
`;

const Message = styled.div`
  padding: 20px;
  background: #ffffff;
  white-space: pre-wrap;
  word-break: break-word;
  font-weight: 400;
  border-radius: ${(props) => (props.isButtons ? '20px' : '4px')};
  font-size: 15px;
  line-height: 21px;
  color: #000000;
  margin-left: 40px;
  margin-right: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const MessageText = styled.p`
  margin: 0;
  margin-top: 10px;
`;

export default TemplatePreview;
