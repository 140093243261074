import React, { useState, useRef } from 'react';
import { Row, Col } from '@zendeskgarden/react-grid';
import TooltipModalComponent from '../../components/tooltip/TooltipModal';

const MenuModal = ({ Trigger, menuItem, rowData }) => {
  const buttonRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState();

  const tooltipModalBody = () => {
    return (
      <div className="grid gap-2">
        {menuItem?.map((item, idx) => {
          if (rowData?.status === 'REJECTED' && item?.name !== 'Duplicate') {
            return (
              <span
                key={idx}
                style={{ color: item?.textColor ? `${item?.textColor}` : '#2F3941' }}
                className={`text-lg mx-3 cursor-pointer`}
                onClick={() => {
                  item.onClick(rowData, item.name);
                  setReferenceElement(null);
                }}
              >
                {item.name}
              </span>
            );
          } else if (rowData?.status !== 'REJECTED' && item?.name !== 'Resubmit') {
            return (
              <span
                key={idx}
                style={{ color: item?.textColor ? `${item?.textColor}` : '#2F3941' }}
                className={`text-lg mx-3 cursor-pointer`}
                onClick={() => {
                  item.onClick(rowData, item.name);
                  setReferenceElement(null);
                }}
              >
                {item.name}
              </span>
            );
          }
        })}
      </div>
    );
  };

  return (
    <Row>
      <Col textAlign="center">
        <div
          ref={buttonRef}
          onClick={() => {
            setReferenceElement(buttonRef.current);
          }}
        >
          {Trigger}
        </div>

        <TooltipModalComponent
          referenceElement={referenceElement}
          setReferenceElement={setReferenceElement}
          body={tooltipModalBody()}
          modalStyle={{ width: 'auto' }}
          onClose={() => {
            buttonRef.current.style.fontWeight = 'normal';
            setReferenceElement(null);
          }}
          placement="auto"
          hasArrow={false}
        />
      </Col>
    </Row>
  );
};

export default MenuModal;
