import { Button } from '@zendeskgarden/react-buttons';
import { Field, Hint, Input, Label, Radio, Textarea, Toggle } from '@zendeskgarden/react-forms';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { onModalClose, zafClient } from '../../api/ZendeskApiService';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useBackend } from '../../api/BackendApiService';
import { Spinner, Inline } from '@zendeskgarden/react-loaders';
import CheckoutForm from './CheckoutForm';
import { GlobalAlert, Well } from '@zendeskgarden/react-notifications';
import moment from 'moment';
import { InfoTooltip } from '../../components/tooltip';
import { Cell, HeaderCell, HeaderRow, Row, Table } from '@zendeskgarden/react-tables';
import { MixPanelInvoiceSent, MixPanelPlanSelection, PAYMENT_TYPE, RECURRENCE_TYPES } from '../send-message/constants';
import InvoiceCheckoutPage from './InvoiceCheckoutPage';
import { Mixpanel } from '../../components/MixPanel';

const STRIP_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIP_PUBLIC_KEY);
const imgPlaceholder = '/payment_success1.jpg';

const { currentUser } = await zafClient.get('currentUser');

function Payment({ upgradePlan, agent, isInvoice }) {
  const {
    getPlans,
    plans,
    getClientSecret,
    notification,
    authData,
    setNotification,
    setPlans,
    cancelSubcriptionApi,
    cancelSubscriptionCancellation,
    payByInvoiceApi,
    getBillingDetails,
    billingDetails,
  } = useBackend();
  const [userCount, setUserCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [proceedPayment, setProceedPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [upgraded, setUpgraded] = useState(false);
  const [recurrenceType, setRecurrenceType] = useState(RECURRENCE_TYPES['year']);
  const [onBoarding, setOnBoarding] = useState(true);
  const [prices, setPrices] = useState(null);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [upcomingInvoiceLoading, setUpcomingInvoiceLoading] = useState(false);
  const [invoicePay, setInvoicePay] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({});

  const onInputValueChange = (e) => {
    let userCnt = userCount;
    let adminCnt = adminCount;
    const val = e.target.value;
    const name = e.target.name;
    if (name === 'userCount') {
      userCnt = Math.abs(val || 0);
      setUserCount(userCnt);
    } else if (name === 'adminCount') {
      adminCnt = Math.abs(val || 0);
      setAdminCount(adminCnt);
    } else {
      setCompanyDetails((values) => ({ ...values, [name]: val }));
    }

    if (!upgradePlan) return;
    const price = selectedPlan?.prices?.find(
      (item) => item.recurrenceType === recurrenceType && item.type === PAYMENT_TYPE.Recurring
    );
    setPrices(price);
    const payload = {
      calculateAmount: true,
      priceId: price?.stripePriceId,
      userCount: userCnt,
      adminCount: adminCnt,
    };

    setUpcomingInvoiceLoading(true);
    getClientSecret(payload, 'PUT')
      .then((res) => setUpcomingInvoice(res.data))
      .catch((error) => console.log('Error : ', error))
      .finally(() => setUpcomingInvoiceLoading(false));
  };

  useEffect(() => {
    if (authData?.subscription && authData?.subscription?.status !== 'canceled') {
      const selectedPlan = plans?.find((ele) => ele.name === authData?.subscription?.plan?.name);
      setSelectedPlan(selectedPlan);
      setPrices(
        selectedPlan?.prices.find(
          (item) => item.recurrenceType === authData?.subscription?.plan?.prices?.recurrenceType
        )
      );
      setRecurrenceType(authData?.subscription?.plan?.prices?.recurrenceType);
      setOnBoarding(authData?.subscription?.enrollOnboardingPlan);
      const adminCount = authData?.subscription?.totalAdminCount - authData?.subscription?.plan?.adminCount;
      const userCount = authData?.subscription?.totalUserCount - authData?.subscription?.plan?.userCount;
      setAdminCount(Math.abs(adminCount || 0));
      setUserCount(Math.abs(userCount || 0));
      setCompanyDetails({
        companyName: billingDetails?.companyName,
        phone: billingDetails?.phone,
        country: billingDetails?.country,
        state: billingDetails?.state,
        city: billingDetails?.city,
        postalCode: billingDetails?.postalCode,
        address: billingDetails?.address,
      });
    }
  }, [authData, plans, billingDetails]);

  useEffect(() => {
    if (authData?.subscription) getBillingDetails();
    if (authData && (!authData?.subscription || authData?.subscription?.status !== 'paid')) {
      Mixpanel.track(MixPanelPlanSelection, {
        Domain: authData?.auth?.domain,
        User: currentUser?.email,
      });
    }
  }, [authData]);

  useEffect(() => {
    zafClient.invoke('resize', { width: '70vw', height: '65vh' });
    setPlans(null);
    getPlans();
    setUpgraded(false);
    zafClient.on('modal.close', () => {
      if (!agent) {
        onModalClose().then((ticketSidebarClient) => {
          ticketSidebarClient.trigger('reload');
        });
      }
    });
  }, []);

  const onBackToPlansPage = () => {
    setProceedPayment(false);
  };

  const createPayload = (price) => {
    let payload = {};
    if (!upgradePlan) payload['enrollOnboardingPlan'] = onBoarding;

    if (upgradePlan)
      payload['totalAmount'] = price?.amount + adminCount * price?.perAdminPrice + userCount * price?.perUserPrice;

    if (!upgradePlan || selectedPlan?.name !== authData?.subscription?.plan?.name) {
      payload['priceId'] = price.stripePriceId;
    }

    if (adminCount) payload['adminCount'] = adminCount;

    if (userCount) payload['userCount'] = userCount;
    payload['billingDetails'] = companyDetails;
    payload['email'] = currentUser.email;
    return payload;
  };

  const proceedToPayment = (cancelOn = null) => {
    if (billingDetails && disableFields()) return;
    if (cancelOn) {
      const notificationObject = {
        title: 'Update Subscription',
        description:
          'If you update your subscription now your previous subscription will not be cancelled and you will be charged according to the plan selected. Are you sure you would like to continue? ',
        button: { text: 'Yes', onClick: () => proceedToPayment(null) },
        secondButton: { text: 'No', onClick: () => setNotification(null) },
        mode: 'success',
        hideClose: true,
      };
      setNotification(notificationObject);
      return;
    }

    if (upgradePlan && !canProceed) return;

    setLoading(true);
    const price = selectedPlan?.prices?.find(
      (item) => item.recurrenceType === recurrenceType && item.type === PAYMENT_TYPE.Recurring
    );
    setPrices(price);
    let payload = createPayload(price);
    getClientSecret(payload, upgradePlan ? 'PUT' : 'POST')
      .then((res) => {
        if (upgradePlan) {
          setUpgraded(true);
        } else {
          setClientSecret(res.data.clientSecret);
          if (stripePromise) setProceedPayment(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let description = 'Check your internet connection and refresh widget to try again';
        let title = 'Couldn’t proceed for payment';
        if (error.status !== 500 && error.status !== 502) {
          description = error.responseJSON.message;
        }
        const notificationObject = {
          title: title,
          description: `${description} (${authData?.auth?.domain})`,
          mode: 'error',
          hideClose: true,
        };
        setNotification(notificationObject);
      });
  };

  const onClickPayByInvoice = async () => {
    setLoading(true);
    const price = selectedPlan?.prices?.find(
      (item) => item.recurrenceType === recurrenceType && item.type === PAYMENT_TYPE.OneTime
    );
    setPrices(price);
    let payload = createPayload(price);
    let invoiceData = await payByInvoiceApi(payload, 'pay-via-invoice');
    if (invoiceData.data) {
      Mixpanel.track(MixPanelInvoiceSent, {
        Domain: authData?.auth?.domain,
        User: currentUser?.email,
        Plan: selectedPlan.name,
        SubscriptionType: 'Invoice',
      });
    }
    setInvoicePay(invoiceData.data);
    setLoading(false);
  };

  const confirmPayByInvoice = () => {
    const notificationObject = {
      title: 'Pay By Invoice',
      description: `Are you sure you want to pay by invoice. Once selected you have ${process.env.REACT_APP_INVOICE_DUE_DAYS} days to pay otherwise your subscription will be deactivated`,
      button: { text: 'Yes', onClick: () => onClickPayByInvoice() },
      secondButton: { text: 'No', onClick: () => setNotification(null) },
      mode: 'warning',
      hideClose: true,
    };
    setNotification(notificationObject);
  };

  const onClickBack = () => {
    zafClient.invoke('destroy');
  };

  const onPlanChange = (value) => {
    setAdminCount(0);
    setUserCount(0);
    const selectedPlan = plans?.find((ele) => ele.name === value);

    setSelectedPlan(selectedPlan);
    const price = selectedPlan?.prices?.find(
      (item) => item.recurrenceType === recurrenceType && item.type === PAYMENT_TYPE.Recurring
    );
    setPrices(price);
    const priceId = price?.stripePriceId;
    if (upgradePlan) {
      setUpcomingInvoiceLoading(true);
      getClientSecret({ priceId: priceId, calculateAmount: true }, 'PUT')
        .then((res) => {
          setUpcomingInvoice(res.data);
          setUpcomingInvoiceLoading(false);
        })
        .catch((error) => {
          console.log('Error : ', error);
          setUpcomingInvoiceLoading(false);
        });
    }
  };

  useEffect(() => {
    if (disableFields()) {
      setCanProceed(false);
      return;
    }
    if (loading) {
      setCanProceed(false);
    } else if (upgradePlan) {
      if (!selectedPlan) return;

      if (selectedPlan?.id === authData?.subscription?.plan?.id) {
        setCanProceed(false);
      } else {
        setCanProceed(true);
        return;
      }

      if (
        adminCount <= authData?.subscription?.totalAdminCount - authData?.subscription?.plan?.adminCount &&
        userCount <= authData?.subscription?.totalUserCount - authData?.subscription?.plan?.userCount
      ) {
        setCanProceed(false);
        return;
      }
      setCanProceed(true);
    } else {
      selectedPlan?.name && companyDetails['companyName']?.length > 0 && companyDetails['phone']?.length > 0
        ? setCanProceed(true)
        : setCanProceed(false);
    }
  }, [selectedPlan, userCount, adminCount, companyDetails]);

  const onClickCancelSubcription = () => {
    const notificationObject = {
      title: 'Cancel Subcription',
      description: 'Are you sure, you want to cancel subscription?',
      button: { text: 'Yes', onClick: () => cancelSubcriptionApi() },
      secondButton: { text: 'No', onClick: () => setNotification(null) },
      mode: 'warning',
      hideClose: true,
    };
    setNotification(notificationObject);
  };

  const onClickCancelSubcriptionCancelation = () => {
    const notificationObject = {
      title: 'Retract Cancellation',
      description: 'Are you sure, you want to retract cancellation?',
      button: { text: 'Yes', onClick: () => cancelSubscriptionCancellation() },
      secondButton: { text: 'No', onClick: () => setNotification(null) },
      mode: 'warning',
      hideClose: true,
    };
    setNotification(notificationObject);
  };

  const onChangeRecurrenceType = (e) => {
    if (e.target.checked) {
      setRecurrenceType(RECURRENCE_TYPES['year']);
      setPrices(selectedPlan?.prices?.find((item) => item.recurrenceType === RECURRENCE_TYPES['year']));
    } else {
      setRecurrenceType(RECURRENCE_TYPES['month']);
      setPrices(selectedPlan?.prices?.find((item) => item.recurrenceType === RECURRENCE_TYPES['month']));
    }
  };

  // To calculate total amount while creating subscription
  const calculateAmount = () => {
    if (!upgradePlan && onBoarding) {
      return (
        prices?.amount +
        adminCount * prices?.perAdminPrice +
        userCount * prices?.perUserPrice +
        selectedPlan?.onboardingAmount +
        adminCount * selectedPlan?.onboardingAdminPrice +
        userCount * selectedPlan?.onboardingUserPrice
      );
    } else return prices?.amount + adminCount * prices?.perAdminPrice + userCount * prices?.perUserPrice;
  };

  // To calculate amount paid by user while upgrading plan
  const calculateUpgradeAmount = () => {
    if (upcomingInvoice) {
      if (
        selectedPlan?.name === authData?.subscription?.plan?.name &&
        adminCount === authData?.subscription?.totalAdminCount - authData?.subscription?.plan?.adminCount &&
        userCount === authData?.subscription?.totalUserCount - authData?.subscription?.plan?.userCount
      )
        return 0;

      return upcomingInvoice?.amountDue;
    }
  };

  const disableFields = () => {
    return authData?.subscription?.status === 'paid' && billingDetails?.customerEmail !== currentUser?.email;
  };

  if (proceedPayment) {
    return (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <CheckoutForm
          selectedPlan={selectedPlan}
          userCount={userCount}
          adminCount={adminCount}
          onClickBack={onBackToPlansPage}
          recurrenceType={recurrenceType}
          onBoarding={onBoarding}
          currentUser={currentUser}
          authData={authData}
        />
      </Elements>
    );
  }

  if ((isInvoice || invoicePay) && authData) {
    return <InvoiceCheckoutPage invoicePay={invoicePay} />;
  }

  if (!plans && !notification) {
    return (
      <CenterPageContainer>
        <Spinner size="60" color="#1f73b7" />
      </CenterPageContainer>
    );
  }

  if (upgraded) {
    return (
      <div style={{ display: 'grid', justifyContent: 'center' }}>
        <StyledImage alt="" src={imgPlaceholder} />
        <PaymentSpan>Payment Successfull</PaymentSpan>
        <MessageSpan>Your payment has been submitted successfully</MessageSpan>
        <StyledButton isPrimary onClick={() => zafClient.invoke('destroy')}>
          Continue to App
        </StyledButton>
      </div>
    );
  }

  return (
    <div>
      {upgradePlan ? (
        <>
          <StyledSpan style={{ marginLeft: '20px' }}>Change Plan</StyledSpan>
          {!authData?.subscription?.cancelOn && billingDetails?.customerEmail === currentUser?.email && (
            <CancleSubButton isPrimary onClick={() => onClickCancelSubcription()}>
              Cancel Subcription
            </CancleSubButton>
          )}
        </>
      ) : (
        <StyledSpan style={{ marginLeft: '20px' }}>Choose Plan</StyledSpan>
      )}
      <HorizontalLine></HorizontalLine>
      <div style={{ marginLeft: '20px', display: 'grid' }}>
        {authData?.subscription?.cancelOn && (
          <GlobalAlert type="warning" style={{ marginRight: '13px' }}>
            <GlobalAlert.Content>
              <GlobalAlert.Title>Subscription Cancelled</GlobalAlert.Title>
              You had cancelled your subscription. You can access your account till{' '}
              {moment(authData?.subscription?.cancelOn).subtract(1, 'days').format('DD-MM-YYYY')}
            </GlobalAlert.Content>
            {billingDetails?.customerEmail === currentUser?.email && (
              <GlobalAlert.Button
                style={{ backgroundColor: 'rgb(31, 115, 183)' }}
                onClick={() => onClickCancelSubcriptionCancelation()}
              >
                Cancel cancellation
              </GlobalAlert.Button>
            )}
          </GlobalAlert>
        )}
        <div style={{ display: 'table' }}>
          <LeftContainer>
            {upgradePlan ? (
              <>
                <br></br>
                <BodySpan>Current Plan: {selectedPlan?.name}</BodySpan>
              </>
            ) : (
              <>
                <StyledSpan>Choose your plan to get started.</StyledSpan>
              </>
            )}
            <br></br>
            <Field>
              <Toggle
                onChange={(event) => onChangeRecurrenceType(event)}
                defaultChecked={true}
                disabled={upgradePlan || disableFields()}
              >
                <YearlyMonthlyToggleLabel>Yearly Subscription</YearlyMonthlyToggleLabel>
              </Toggle>
            </Field>
            <br></br>
            <SelectPlanText>Please select a plan below</SelectPlanText>
            <div style={{ marginLeft: '10px', marginTop: '20px' }}>
              {plans?.map((plan, idx) => {
                let isDisabled = false;
                if (upgradePlan) {
                  const activePlanPrice = authData?.subscription?.plan?.prices?.amount;
                  if (plan?.prices?.find((item) => item.recurrenceType === recurrenceType).amount < activePlanPrice)
                    isDisabled = true;
                }

                return (
                  <div key={idx}>
                    <Field>
                      <Radio
                        isCompact
                        name="plan"
                        value={plan.name}
                        checked={selectedPlan?.name === plan.name}
                        onChange={(event) => onPlanChange(event.target.value)}
                        disabled={isDisabled || disableFields()}
                      >
                        <RadioLable>
                          {plan.name}{' '}
                          {`($${
                            recurrenceType === 'MONTHLY'
                              ? plan.prices.find((item) => item.recurrenceType === recurrenceType).amount
                              : plan.prices.find((item) => item.recurrenceType === recurrenceType).amount / 12
                          } per month)`}
                        </RadioLable>
                        <RadioHint>
                          Includes {plan?.adminCount > 0 && `${plan.adminCount} admin`}
                          {plan?.adminCount > 0 && plan.userCount > 0 ? ' & ' : ' '}
                          {plan.userCount > 0 && `${plan.userCount} users`}
                        </RadioHint>
                      </Radio>
                    </Field>
                    <br></br>
                  </div>
                );
              })}
            </div>
            <br></br>
            <Field>
              <ToggleContainer>
                <Toggle
                  onChange={(event) => setOnBoarding(event.target.checked)}
                  defaultChecked={upgradePlan ? authData?.subscription?.enrollOnboardingPlan : true}
                  disabled={upgradePlan || disableFields()}
                >
                  <YearlyMonthlyToggleLabel>On Boarding</YearlyMonthlyToggleLabel>
                </Toggle>
                <InfoTooltip tooltip="This amount will be charged only once during subscription creation" />
              </ToggleContainer>
            </Field>
          </LeftContainer>
          {selectedPlan && (
            <RightContainer>
              <br></br>
              <div style={{ marginBottom: '10px' }}>
                <BodySpan>Order Summary</BodySpan>
                {upcomingInvoice && (
                  <InputLabel style={{ float: 'right', fontWeight: '600' }}>
                    Period:{' '}
                    <RadioHint>
                      {moment(1000 * upcomingInvoice?.plans[0]?.period?.start).format('DD/MM/YYYY hh:mm A')} to{' '}
                      {moment(1000 * upcomingInvoice?.plans[0]?.period?.end).format('DD/MM/YYYY hh:mm A')}
                    </RadioHint>
                  </InputLabel>
                )}
              </div>
              <CardContainer isFloating>
                {upcomingInvoiceLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <Spinner size="60" color="#1f73b7" />{' '}
                  </div>
                ) : (
                  <Table style={{ borderCollapse: 'inherit' }} onFocus={null} isReadOnly>
                    <HeaderRow>
                      <StylesHeaderCell style={{ width: '60%' }}>Plans</StylesHeaderCell>
                      <StylesHeaderCell>Quantity</StylesHeaderCell>
                      <StylesHeaderCell>Amount</StylesHeaderCell>
                    </HeaderRow>
                    {!upcomingInvoice ? (
                      <>
                        {selectedPlan && (
                          <Row>
                            <FirstCell>
                              <div>
                                <RadioLable>{selectedPlan?.name}</RadioLable>
                                <RadioHint>
                                  {recurrenceType === 'YEARLY'
                                    ? `$${prices?.amount / 12} per month`
                                    : `$${prices?.amount} per month`}
                                </RadioHint>
                              </div>
                            </FirstCell>
                            <Cell>{recurrenceType === 'YEARLY' ? '12' : '1'}</Cell>
                            <Cell>${prices?.amount}</Cell>
                          </Row>
                        )}
                        {adminCount > 0 && (
                          <Row>
                            <FirstCell>
                              <div>
                                <RadioLable>Additional Admin</RadioLable>
                                <RadioHint>
                                  {recurrenceType === 'YEARLY'
                                    ? `$${prices?.perAdminPrice / 12} per user per month`
                                    : `$${prices?.perAdminPrice} per user per month`}
                                </RadioHint>
                              </div>
                            </FirstCell>
                            <Cell>{recurrenceType === 'YEARLY' ? adminCount * 12 : adminCount}</Cell>
                            <Cell>${adminCount * prices?.perAdminPrice}</Cell>
                          </Row>
                        )}
                        {userCount > 0 && (
                          <Row>
                            <FirstCell>
                              <div>
                                <RadioLable>Additional User</RadioLable>
                                <RadioHint>
                                  {recurrenceType === 'YEARLY'
                                    ? `$${prices?.perUserPrice / 12} per user per month`
                                    : `${prices?.perUserPrice} per user per month`}
                                </RadioHint>
                              </div>
                            </FirstCell>
                            <Cell>{recurrenceType === 'YEARLY' ? userCount * 12 : userCount}</Cell>
                            <Cell>${userCount * prices?.perUserPrice}</Cell>
                          </Row>
                        )}
                        {!upgradePlan && onBoarding && (
                          <Row>
                            <FirstCell>
                              <div>
                                <RadioLable>{selectedPlan?.name} OnBoarding</RadioLable>
                                <RadioHint>${selectedPlan?.onboardingAmount} one time fees</RadioHint>
                              </div>
                            </FirstCell>
                            <Cell>1</Cell>
                            <Cell>${selectedPlan?.onboardingAmount}</Cell>
                          </Row>
                        )}
                        {!upgradePlan && onBoarding && adminCount > 0 && (
                          <Row>
                            <FirstCell>
                              <div>
                                <RadioLable>Additional Admin OnBoarding</RadioLable>
                                <RadioHint>${selectedPlan?.onboardingAdminPrice} per user one time fees</RadioHint>
                              </div>
                            </FirstCell>
                            <Cell>{adminCount}</Cell>
                            <Cell>${adminCount * selectedPlan?.onboardingAdminPrice}</Cell>
                          </Row>
                        )}
                        {!upgradePlan && onBoarding && userCount > 0 && (
                          <Row>
                            <FirstCell>
                              <div>
                                <RadioLable>Additional User OnBoarding</RadioLable>
                                <RadioHint>${selectedPlan?.onboardingUserPrice} per user one time fees</RadioHint>
                              </div>
                            </FirstCell>
                            <Cell>{userCount}</Cell>
                            <Cell>${userCount * selectedPlan?.onboardingUserPrice}</Cell>
                          </Row>
                        )}
                        <br></br>
                        <Row style={{ backgroundColor: '#d6d6d6' }}>
                          <FirstCell>
                            <RadioLable>Amount to be Paid</RadioLable>
                          </FirstCell>
                          <Cell></Cell>
                          <Cell>
                            <RadioLable>${calculateAmount()}</RadioLable>
                          </Cell>
                        </Row>
                      </>
                    ) : (
                      <>
                        {upcomingInvoice?.plans?.reverse().map((item, idx) => {
                          return (
                            <Row key={idx}>
                              <FirstCell>
                                <div>
                                  <RadioLable>{item?.description}</RadioLable>
                                </div>
                              </FirstCell>
                              <Cell>{recurrenceType === 'MONTHLY' ? item?.quantity : item?.quantity * 12}</Cell>
                              <Cell>{item?.amount >= 0 ? `$${item?.amount}` : `-$${Math.abs(item?.amount)}`}</Cell>
                            </Row>
                          );
                        })}
                        <br></br>
                        <Row style={{ backgroundColor: '#d6d6d6' }}>
                          <FirstCell>
                            <RadioLable style={{ fontWeight: '750' }}>Amount to be Paid</RadioLable>
                          </FirstCell>
                          <Cell></Cell>

                          <Cell style={{ fontWeight: '750' }}>${calculateUpgradeAmount()}</Cell>
                        </Row>
                        <Row style={{ backgroundColor: '#d6d6d6' }}>
                          <FirstCell>
                            <RadioLable style={{ fontWeight: '750' }}>Subscription Amount</RadioLable>
                          </FirstCell>
                          <Cell></Cell>

                          <Cell style={{ fontWeight: '750' }}>${calculateAmount()}</Cell>
                        </Row>
                      </>
                    )}
                  </Table>
                )}
              </CardContainer>
            </RightContainer>
          )}
        </div>
        <HorizontalLine></HorizontalLine>
        <br></br>
        <RowContainer>
          <InputContainer>
            <InputLabel>
              Additional Users <> {selectedPlan?.userCount > 0 && <>{`($${prices?.perUserPrice} per user)`}</>}</>
            </InputLabel>
            <Input
              type="number"
              value={userCount}
              style={{ width: '400px' }}
              isCompact
              name="userCount"
              onChange={(e) => onInputValueChange(e)}
              disabled={selectedPlan?.userCount === 0 || disableFields()}
              min={0}
            />
          </InputContainer>
          <InputContainer>
            <InputLabel>
              Additional Admins <> {selectedPlan?.adminCount > 0 && <>{`($${prices?.perAdminPrice} per user)`}</>}</>
            </InputLabel>
            <Input
              type="number"
              value={adminCount}
              style={{ width: '400px' }}
              isCompact
              name="adminCount"
              onChange={(e) => onInputValueChange(e)}
              disabled={selectedPlan?.adminCount === 0 || disableFields()}
              min={0}
            />
          </InputContainer>
        </RowContainer>
        <HorizontalLine></HorizontalLine>
        <SeperatorLabel>Company Details</SeperatorLabel>
        <br></br>
        <br></br>
        <div style={{ display: 'grid' }}>
          <RowContainer>
            <InputContainer style={{ textAlign: 'left' }}>
              <InputLabel>
                <span style={{ color: '#03363d' }}>Company Name</span>
              </InputLabel>
              <Input
                isCompact
                name="companyName"
                style={{ width: '400px' }}
                value={companyDetails['companyName']}
                onChange={(event) => onInputValueChange(event)}
                placeholder="Please enter your company name (mandatory)"
                disabled={authData?.subscription?.status === 'paid'}
              />
            </InputContainer>
            <InputContainer style={{ textAlign: 'left' }}>
              <InputLabel>
                <span style={{ color: '#03363d' }}>Contact Number</span>
              </InputLabel>
              <Input
                isCompact
                name="phone"
                style={{ width: '400px' }}
                value={companyDetails['phone']}
                onChange={(event) => onInputValueChange(event)}
                placeholder="Please enter your contact number (mandatory)"
                type="number"
                disabled={authData?.subscription?.status === 'paid'}
              />
            </InputContainer>
          </RowContainer>
          <br></br>
          <RowContainer>
            <InputContainer style={{ textAlign: 'left' }}>
              <InputLabel>
                <span style={{ color: '#03363d' }}>Country</span>
              </InputLabel>
              <Input
                isCompact
                name="country"
                style={{ width: '400px' }}
                value={companyDetails['country']}
                onChange={(event) => onInputValueChange(event)}
                placeholder={!billingDetails && 'Please enter your country (optional)'}
                disabled={authData?.subscription?.status === 'paid'}
              />
            </InputContainer>
            <InputContainer style={{ textAlign: 'left' }}>
              <InputLabel>
                <span style={{ color: '#03363d' }}>State</span>
              </InputLabel>
              <Input
                isCompact
                name="state"
                style={{ width: '400px' }}
                value={companyDetails['state']}
                onChange={(event) => onInputValueChange(event)}
                placeholder={!billingDetails && 'Please enter your state (optional)'}
                disabled={authData?.subscription?.status === 'paid'}
              />
            </InputContainer>
          </RowContainer>
          <br></br>
          <RowContainer>
            <InputContainer style={{ textAlign: 'left' }}>
              <InputLabel>
                <span style={{ color: '#03363d' }}>City</span>
              </InputLabel>
              <Input
                isCompact
                name="city"
                style={{ width: '400px' }}
                value={companyDetails['city']}
                onChange={(event) => onInputValueChange(event)}
                placeholder={!billingDetails && 'Please enter your city (optional)'}
                disabled={authData?.subscription?.status === 'paid'}
              />
            </InputContainer>
            <InputContainer style={{ textAlign: 'left' }}>
              <InputLabel>
                <span style={{ color: '#03363d' }}>Postal Code</span>
              </InputLabel>
              <Input
                isCompact
                name="postalCode"
                style={{ width: '400px' }}
                value={companyDetails['postalCode']}
                onChange={(event) => onInputValueChange(event)}
                placeholder={!billingDetails && 'Please enter your postal code (optional)'}
                type="number"
                disabled={authData?.subscription?.status === 'paid'}
              />
            </InputContainer>
          </RowContainer>
          <br></br>
          <InputContainer style={{ textAlign: 'left' }}>
            <InputLabel>
              <span style={{ color: '#03363d' }}>Address</span>
            </InputLabel>
            <Textarea
              isCompact
              name="address"
              style={{ width: '400px' }}
              value={companyDetails['address']}
              onChange={(event) => onInputValueChange(event)}
              placeholder={!billingDetails && 'Please enter your address (optional)'}
              isResizable
              disabled={authData?.subscription?.status === 'paid'}
            />
          </InputContainer>
        </div>
      </div>
      <br></br>
      <br></br>
      <ButtonsContainer>
        <StyledButton onClick={() => onClickBack()}>Back</StyledButton>

        <Container>
          <StyledButton
            isPrimary
            onClick={() => proceedToPayment(authData?.subscription?.cancelOn)}
            disabled={!canProceed || loading}
          >
            {loading && <Inline />}
            <span hidden={loading}>Proceed to payment</span>
          </StyledButton>
        </Container>
        {(!authData?.subscription ||
          authData?.subscription?.status === 'incomplete' ||
          authData?.subscription?.status === 'failed') &&
          authData?.auth?.payViaInvoice && (
            <Container>
              <StyledButton isPrimary onClick={() => confirmPayByInvoice()} disabled={!canProceed || loading}>
                {loading && <Inline />}
                <span hidden={loading}>Pay By Invoice</span>
              </StyledButton>
            </Container>
          )}
      </ButtonsContainer>
    </div>
  );
}

const FirstCell = styled(Cell)`
  width: 60%;
`;

const StylesHeaderCell = styled(HeaderCell)`
  font-weight: 700;
  color: #03363d;
`;

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #03363d;
`;

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
  height: 100%;
`;

const HorizontalLine = styled.hr`
  margin-top: 22px;
  height: 1px;
  border-top: 0.25px solid #eff0f1;
  width: 100%;
`;

const BodySpan = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #03363d;
`;

const InputLabel = styled(Label)`
  font-size: 12px;
  font-weight: 700;
  color: #03363d;
  line-height: 16px;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin: 0 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const Container = styled.div`
  display: grid;
  gap: 23px;
`;

const SelectPlanText = styled.span`
  font-size: 11px;
  font-weight: 350;
  color: #03363d;
`;

const RadioLable = styled(Label)`
  font-size: 14px;
  font-weight: 600;
`;

const RadioHint = styled(Hint)`
  font-size: 12px;
  font-weight: 400;
`;

const InputContainer = styled(Field)`
  display: grid;
`;

const PaymentSpan = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #03363d;
  text-align: center;
`;

const MessageSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #03363d;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledImage = styled.img`
  height: 200px;
  justify-self: center;
`;

const CancleSubButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin-right: 15px;
`;

const YearlyMonthlyToggleLabel = styled(Label)`
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  display: flex;
  height: 23px;
  color: #03363d;
  font-weight: 700;
`;

const ToggleContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const LeftContainer = styled.div`
  width: 40%;
  float: left;
  display: grid;
`;

const RightContainer = styled.div`
  width: 57%;
  float: left;
  display: grid;
`;

const CardContainer = styled(Well)`
  background-color: #e5e5e545;
  border-radius: 10px;
  box-shadow: rgba(23, 73, 77, 0.15) 0px 3px 5px 0px;
`;

const SeperatorLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: #03363d;
  margin-top: 10px;
`;

const RowContainer = styled.div`
  display: flex;
  justif-content: space-between;
  margin-right: 10px;
  gap: 20px;
`;

export default Payment;
