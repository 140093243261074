import styled from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CommonPage from '../pages';
import { BackendApiProvider } from '../api/BackendApiService';
import SettingsUi from '../pages/settings';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import SummaryTabs from '../pages/SummaryTabs';
import Agent from '../pages/agents';
import Payment from '../pages/payment';
import { UpgradeModal } from '../pages/payment/UpgradeModal';
import TierLimitUpgrade from '../pages/settings/tierLImit';
import TemplateViewer from '../pages/template-viewer';
import TemplateWizard from '../pages/template-wizards';
import CreateTemplate from '../pages/create-template';
import Layout from '../layout';
import LayoutService from '../layout/service';

function App() {
  const layoutService = LayoutService();

  return (
    <ThemeProvider>
      <BackendApiProvider>
        <OutlineContainer>
          <BrowserRouter>
            <Layout headerData={layoutService.headerData}>
              <Routes>
                <Route path="/zd-user" element={<CommonPage layoutService={layoutService} />} />
                <Route path="/zd-tickets" element={<CommonPage layoutService={layoutService} />} />
                <Route path="/zd-modal" element={<CommonPage layoutService={layoutService} />} />
                <Route path="/zd-summary-tabs" element={<SummaryTabs />} />
                <Route path="/zd-agent-modal" element={<Agent />} />
                <Route path="/zd-payment-modal" element={<Payment />} />
                <Route path="/zd-change-plan-modal" element={<Payment upgradePlan={true} />} />
                <Route path="/zd-invoice-modal" element={<Payment isInvoice={true} />} />
                <Route path="/zd-upgrade-modal" element={<UpgradeModal />} />
                <Route path="/zd-tier-limit-modal" element={<TierLimitUpgrade />} />
                <Route path="/settings" element={<SettingsUi layoutService={layoutService} />} />
                <Route path="/template-wizard" element={<TemplateWizard layoutService={layoutService} />} />
                <Route path="/create-template" element={<CreateTemplate layoutService={layoutService} />} />
                <Route path="/template-viewer" element={<TemplateViewer layoutService={layoutService} />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </OutlineContainer>
      </BackendApiProvider>
    </ThemeProvider>
  );
}

const OutlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin: 0 4px;
`;

export default App;
