import { Button } from '@zendeskgarden/react-buttons';
import { Alert, Close, Title } from '@zendeskgarden/react-notifications';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useBackend } from '../../api/BackendApiService';

function Notification(props) {
  let { notification, setNotification, userLogin, clientData } = useBackend();
  const { lastPage, onClose, title, description, button, mode, hideClose, showNotification } = props;
  const naviagte = useNavigate();
  if (showNotification)
    notification = { ...notification, title, description, button, mode, hideClose, showNotification };
  let dismissStyle = {};
  if (notification.mode === 'error' && !notification.button?.icon) {
    dismissStyle = {
      border: 'none',
      backgroundColor: '#ff7875',
      color: '#fff',
    };
  } else if (notification.mode === 'success' || notification.button?.icon) {
    dismissStyle = {
      border: 'none',
      backgroundColor: '#1f73b7',
      color: '#fff',
    };
  } else if (notification.mode === 'warning') {
    dismissStyle = {
      border: 'none',
      backgroundColor: '#703815',
      color: '#fff',
    };
  } else if (notification.mode === 'warning') {
    dismissStyle = {
      border: 'none',
      backgroundColor: '#703815',
      color: '#fff',
    };
  } else {
    dismissStyle = {
      border: 'none',
      backgroundColor: '#000000',
      color: '#fff',
    };
  }

  const clickNotificationButton = () => {
    if (showNotification) {
      onClose();
      return;
    }
    if (notification.button.onClick === 'yes') {
      userLogin();
      naviagte(lastPage, {
        replace: true,
        state: { clientData: clientData, tab: 'sendMessageTab', clearTemplate: true },
      });
    } else if (notification.button.onClick) {
      notification.button.onClick();
    }
    setNotification(null);
  };
  return (
    <AlertRoot type={notification.mode}>
      <AlertTitle type={notification.mode}>{notification.title}</AlertTitle>

      <>{notification.description}</>

      {!notification.hideClose && (
        <AlertClose
          aria-label="Close Alert"
          onClick={() => {
            setNotification(null);
          }}
        />
      )}
      {notification.button && (
        <>
          <br />
          <br />
          <div className="flex flex-wrap">
            <a
              href={notification.button.url}
              target="_blank"
              rel="noreferrer"
              onClick={() => !notification.button.url && clickNotificationButton()}
              style={{ ...notification.button.style }}
            >
              <StyledButton
                style={{ ...dismissStyle, ...notification.button.style }}
                type={notification.mode}
                icon={notification.button.icon}
              >
                <div className="flex gap-2">
                  {notification.button.icon && (
                    <img src={notification.button.icon} alt={notification.button.altText} className="-mt-[2px]" />
                  )}
                  <span style={{ textDecoration: 'none' }}>{notification.button.text}</span>
                </div>
              </StyledButton>
            </a>
            {notification.secondButton && (
              <StyledButton
                style={{
                  ...dismissStyle,
                  ...notification.button.style,
                  ...(notification.secondButton.style ? {} : { marginLeft: '10px' }),
                }}
                onClick={() => notification.secondButton.onClick()}
                type={notification.mode}
                icon={notification.button.icon}
              >
                <div className="flex gap-2">
                  {notification.secondButton.icon && (
                    <img src={notification.secondButton.icon} alt={notification.secondButton.altText} />
                  )}
                  <span style={{ textDecoration: 'none' }}>{notification.secondButton.text}</span>
                </div>
              </StyledButton>
            )}
          </div>
        </>
      )}
    </AlertRoot>
  );
}

const AlertRoot = styled(Alert)`
  color: ${(props) =>
    props.type === 'error'
      ? '#7d3d43'
      : props.type === 'success'
      ? '#0b3b29'
      : props.type === 'info'
      ? '#2f3941'
      : '#0b3b29'};
  width: 90%;

  box-shadow: 0px 20px 28px rgba(4, 68, 77, 0.15);
  border-radius: 4px;
  border: ${(props) =>
    props.type === 'error'
      ? '1px solid #ff7875'
      : (props.type === 'success' || props.type === 'info') && '1px solid #21dcc5'};
  background: ${(props) =>
    props.type === 'error' ? '#f5e6ed' : props.type === 'success' ? '#dcf3fa' : props.type === 'info' && '#eeeef3'};
  & > svg {
    color: ${(props) =>
      props.type === 'error' ? '#ff7875' : (props.type === 'success' || props.type === 'info') && '#21dcc5'};
  }
`;

const AlertTitle = styled(Title)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: ${(props) => (props.type === 'error' ? '#ff7875' : props.type === 'success' && '#21dcc5')};
`;

const AlertClose = styled(Close)`
  color: #68737d;
`;

const StyledButton = styled(Button)`
  border: ${(props) =>
    props.type === 'error'
      ? '1px solid #ff7875'
      : props.type === 'success'
      ? '1px solid #1f73b7'
      : props.type === 'warning' && '1px solid #703815'};
  color: ${(props) =>
    props.type === 'error' ? '#f5e6ed' : props.type === 'success' ? '#0b3b29' : props.type === 'warning' && '#703815'};
`;

export default Notification;
