import { Dropdown, Field, Item, Label, Menu, Multiselect } from '@zendeskgarden/react-dropdowns';
import { Tag } from '@zendeskgarden/react-tags';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../../../api/BackendApiService';
import useDebounce from '../../../../components/useDebounce';
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';

function Tags(props) {
  const { onChange, valueTags } = props;
  const [selectedTagsValue, setSelectedTagsValue] = useState([]);
  const [tagList, setTagList] = useState([]);
  const { tags, getTags } = useBackend();

  useEffect(() => {
    setTagList(tags);
  }, [tags]);

  useEffect(() => {
    setSelectedTagsValue(valueTags);
  }, [valueTags]);

  const onChangeTagsValue = (item) => {
    setSelectedTagsValue(item);
    onChange('tags', item);
  };

  // Use the correct useDebounce import
  const debounceSearch = useDebounce((value) => getTags(value), 600);

  return (
    <Dropdown
      selectedItems={selectedTagsValue}
      onSelect={(item) => onChangeTagsValue(item)}
      downshiftProps={{ defaultHighlightedIndex: 0 }}
      onInputValueChange={(value) => debounceSearch(value)}
    >
      <Field>
        <Multiselect
          start={<SearchIcon />}
          isCompact
          disabled={false}
          renderItem={({ value, removeValue }) => {
            return (
              <Tag>
                <span>{value}</span>
                <Tag.Close onClick={() => removeValue()} />
              </Tag>
            );
          }}
        />
      </Field>

      <Menu>
        {tagList && tagList.length === 0 ? (
          <Item disabled>No Tags Found</Item>
        ) : (
          tagList &&
          tagList.map((option) => (
            <Item key={option.name} value={option.name}>
              {option.name}
            </Item>
          ))
        )}
      </Menu>
    </Dropdown>
  );
}

export default Tags;
